const AccountReducer = (state, action) => {
  const user = state.user

  switch (action.type) {
    case 'SET_ADMIN_PRIVILEGES':
      user.isAdmin = action.payload

      return {
        ...state,
        user: user,
      }
    case 'UPDATE_USER_TYPE':
      user.isSupporter = action.payload.indexOf('supporter') >= 0
      user.isVolunteer = action.payload.indexOf('volunteer') >= 0
      user.wasGrantedTicket = action.payload.indexOf('ticket holder') >= 0

      return {
        ...state,
        user: user,
      }
    case 'PROFILE_UPDATE':
      return {
        ...state,
        profile: action.payload
      };
    case 'LOGIN':
      return {
        ...state,
        user: action.payload
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
        profile: null
      };
    default:
      return state;
  }
};

export default AccountReducer;