import React, { useState, useEffect } from 'react'
import DashboardContainer, { DashboardInsideContainer } from "components/DashboardContainer";
import styled from "styled-components";
import CommunityHeroPreview from "components/CommunityHeroPreview"
import { EventLoadingPreview } from "components/EventPreview";

const CommunityHeroGrid = styled.div`
    display: grid;
    margin-top: calc(20px + 2vw);
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: calc(10px + 1vw);
    grid-row-gap: 32px;
    max-width: 1000px;
    
    @media only screen and (max-width: 1480px) {
        width: 100%;
        grid-column-gap: calc(5px + 0.5vw);
        grid-row-gap: 32px;
    }
    @media only screen and (max-width: 1280px) {
        width: 100%;
        grid-column-gap: 12px;
        grid-row-gap: 32px;
    }

    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`

const CommunityTagline = styled.p`
    color: #677E9A;
    max-width: 779px;
    font-weight: 500;
    font-size: 24px;
`

const CommunityHeroes = () => {
    const [loading, setLoading] = useState(true)
    const [heroData, setHeroData] = useState([])

    useEffect(() => {
        fetch("https://api.airtable.com/v0/appZGpRTkEij893tS/Community%20Heroes?maxRecords=50&view=Grid+view", {
            "headers": {
                "authorization": "Bearer keyNfd02JL9fMEUlx",
                "x-airtable-application-id": "appZGpRTkEij893tS",
                "content-type": "application/json",
                "x-airtable-user-agent": "Airtable.js/undefined"
            },
            "method": "GET",
            "mode": "cors"
        })
            .then(res => res.json())
            .then(json => {
                setHeroData(json.records)
                setLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setLoading(false)
            })
    }, [])

    return(
        <>
            <DashboardContainer>
                <DashboardInsideContainer className="mt-5">
                    <h1 className="text-xxxl text-weight-600 mb-4">Community Heroes</h1>
                    <CommunityTagline>We would like to thank everyone who generously helped us
                    support attendee tickets.</CommunityTagline>
                    <CommunityHeroGrid>

                        {loading &&
                            [1, 2, 3, 4, 5, 6].map((index) => (
                            <EventLoadingPreview key={index} />
                        ))}

                        {!loading &&
                            heroData.map((data, index) => (
                                <CommunityHeroPreview name={data.fields.name} index={index} image={data.fields.profile_url} country={data.fields.country} url={data.fields.attendee_url} badges={data.fields.badges} />
                            ))
                        }

                    </CommunityHeroGrid>
                </DashboardInsideContainer>
            </DashboardContainer>
        </>
    )
}


export default CommunityHeroes