import React from 'react';
import AccountContainer from 'components/AccountContainer';
import styled from "styled-components";
import { AccountContext } from 'Account/store';
import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import twitterIcon from 'components/icons/twitter.svg';
import facebookIcon from 'components/icons/fb.svg';
import githubIcon from 'components/icons/github.svg';
import linkedInIcon from 'components/icons/linkedin.svg';
import websiteIcon from 'components/icons/web.svg';
import UploadProfileImage from './UploadProfileImage';
import CountrySelect from 'components/CountrySelect';

const CreateProfileContainer = styled(AccountContainer)`
    form {
        padding:0px;
    }
    label {
        width:100%;
        color:#2C2929;
        text-align:left;
    }
    p {
        text-align: left;
    }
    .social-icon {
        padding-top:6px;
        padding-bottom:6px;
        padding-right:0px;

        -ms-flex: 0 0 20px;
        flex: 0 0 20px;
    }
    .social-icon img {
        height:27px;
    }
`;

class CreateProfileScreen extends React.Component {
    static contextType = AccountContext;

    constructor(props) {
        super(props);
        this.createProfile = this.createProfile.bind(this);
        this.state = {
            loading: false,
            message: null,
            imageFile: null,
            firstName: "",
            lastName: "",
            pronouns: "",
            country: "",
            techStack: [ ],
            role: "",
            organisation: "",
            twitter: "",
            facebook: "",
            github: "",
            linkedIn: "",
            website: "",
            privacy: "anyone"
        };
    }

    createProfile(event) {
        event.preventDefault();

        var [state, dispatch] = this.context;
        if (!this.inputsAreValid() && state.user) { 
            return
        }

        const userID = state.user.uid;
        const profile = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            emailAddress: state.user.email,
            pronouns: this.state.pronouns,
            country: this.state.country,
            techStack: this.state.techStack,
            imageURL: null,
            thumbnailURL: null,
            role: this.nullOrSet(this.state.role),
            badges: [],
            organisation: this.state.organisation,
            socials: {
                twitter: this.nullOrSet(this.state.twitter),
                facebook: this.nullOrSet(this.state.facebook),
                github: this.nullOrSet(this.state.github),
                linkedIn: this.nullOrSet(this.state.linkedIn),
                website: this.nullOrSet(this.state.website)
            },
            privacy: this.state.privacy
        };

        this.setState({ loading: true });

        if (this.state.imageFile) {
            this.uploadImageToFirebase(userID, this.state.imageFile, (downloadURL, thumbnailURL) => {
                profile.imageURL = downloadURL;
                profile.thumbnailURL = thumbnailURL;

                this.uploadProfileToFirebase(userID, profile, () => {
                    dispatch({ type: "PROFILE_UPDATE", payload: profile });
                });
            });
        } else {
            this.uploadProfileToFirebase(userID, profile, () => {
                dispatch({ type: "PROFILE_UPDATE", payload: profile });
            });
        }
    }

    uploadImageToFirebase(userID, image, completion) {
        const extension = image.name.split('.').pop();

        firebase
            .storage()
            .ref()
            .child("images/" + userID + "." + extension)
            .put(image)
            .then((snapshot) => {
                snapshot.ref.getDownloadURL().then((downloadURL) => {
                    firebase
                        .storage()
                        .ref()
                        .child("images/" + userID + "_200x200." + extension).getDownloadURL()
                        .then((thumbnailURL) => {
                            completion(downloadURL, thumbnailURL)
                        }); 
                })
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    message: error.message
                });
            })
    }

    uploadProfileToFirebase(userID, profile, completion) {
        firebase
            .database()
            .ref('users/' + userID)
            .set(profile)
            .then(() => {
                this.setState({ loading: false });
                completion();
            })
            .catch((error) => {
                this.setState({ 
                    loading: false,
                    message: error.message
                });
            });
    }

    nullOrSet(value) {
        return value === "" ? null : value
    }

    atLeastOneSocialEntered() {
        return this.state.twitter !== "" ||
            this.state.facebook !== "" ||
            this.state.github !== "" ||
            this.state.linkedIn !== "" ||
            this.state.website !== ""
    }

    inputsAreValid() {
        return this.state.firstName !== "" &&
            this.state.lastName !== "" &&
            this.state.pronouns !== "" &&
            this.state.country !== "" &&
            this.state.techStack.length > 0 &&
            this.state.organisation !== "" &&
            this.atLeastOneSocialEntered() &&
            !this.state.loading;
    }

    handleInputChange(name, value) {
        this.setState({ [name]: value });
    }
    
    handleCheckboxChange(group, item, checked) {
        var value = this.state[group];
        if (checked) {
            value.push(item);
        } else {
            value = value.filter((x) => x !== item);
        }
        this.setState({ [group]: value });
    }

    handleImageSelection(file) {
        this.setState({ imageFile: file });
    }

    privacyDescription() {
        switch (this.state.privacy) {
        case "private":
            return (<span>Your profile is visible to you and <i>lead organisers</i> of WWDC21 Community Week.</span>);
        case "attendees":
            return "Your profile is only viewable on attendees dashboard."
        default:
            return "Your profile can be seen on our public attendees page.";
        }
    }

    render() {
        return (
            <CreateProfileContainer>
                <h1>Create Profile</h1>
                <p>{this.state.message}</p>

                <form onSubmit={this.createProfile}>
                    <UploadProfileImage onChange={(file) => this.handleImageSelection(file) } />

                    <label htmlFor="first-name">Let us get to know you</label>
                    <div className="input-group">
                        <input 
                            type="text" 
                            name="firstName"
                            id="first-name"
                            placeholder="First name*"
                            className="form-control mb-4"
                            value={this.state.firstName}
                            onChange={(event) =>
                                this.handleInputChange("firstName", event.target.value)
                            }
                        />
                        <input 
                            type="text" 
                            name="lastName"
                            placeholder="Last name*"
                            className="form-control mb-4"
                            value={this.state.lastName}
                            onChange={(event) =>
                                this.handleInputChange("lastName", event.target.value)
                            }
                        />
                    </div>

                    <label htmlFor="pronouns">Pronouns</label>
                    <input 
                        type="text" 
                        id="pronouns"
                        name="Pronouns"
                        placeholder="she/her/hers*"
                        className="form-control mb-4"
                        value={this.state.pronouns}
                        onChange={(event) =>
                            this.handleInputChange("pronouns", event.target.value)
                        }
                    />

                    <label>Country</label>
                    <CountrySelect onChange={(event) => {             
                        this.handleInputChange("country", event.target.selectedOptions[0].value)
                    }}/>
                    <label>Apple tech stack</label><br/>
                    <p className="text-sm text-cobble">Select at least one</p>
            
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={this.state.techStack.indexOf("ios") !== -1}
                            onChange={(event) =>
                                this.handleCheckboxChange("techStack", "ios", event.target.checked)
                            }
                            id="stack-ios" />
                        <label className="form-check-label" htmlFor="stack-ios">
                            iOS
                                    </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={this.state.techStack.indexOf("macos") !== -1}
                            onChange={(event) =>
                                this.handleCheckboxChange("techStack", "macos", event.target.checked)
                            }
                            id="stack-macos" />
                        <label className="form-check-label" htmlFor="stack-macos">
                            macOS
                                    </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={this.state.techStack.indexOf("tvos") !== -1}
                            onChange={(event) =>
                                this.handleCheckboxChange("techStack", "tvos", event.target.checked)
                            }
                            id="stack-tvos" />
                        <label className="form-check-label" htmlFor="stack-tvos">
                            tvOS
                                    </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={this.state.techStack.indexOf("watchos") !== -1}
                            onChange={(event) =>
                                this.handleCheckboxChange("techStack", "watchos", event.target.checked)
                            }
                            id="stack-watchos" />
                        <label className="form-check-label" htmlFor="stack-watchos">
                            watchOS
                                    </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={this.state.techStack.indexOf("server") !== -1}
                            onChange={(event) =>
                                this.handleCheckboxChange("techStack", "server", event.target.checked)
                            }
                            id="stack-server" />
                        <label className="form-check-label" htmlFor="stack-server">
                            Server-side Swift
                                    </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={this.state.techStack.indexOf("beginner") !== -1}
                            onChange={(event) =>
                                this.handleCheckboxChange("techStack", "beginner", event.target.checked)
                            }
                            id="stack-beginner" />
                        <label className="form-check-label" htmlFor="stack-beginner">
                            Beginning to Learn Apple Dev't
                                    </label>
                    </div>
                    <div className="form-check mb-4">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={this.state.techStack.indexOf("exploring") !== -1}
                            onChange={(event) =>
                                this.handleCheckboxChange("techStack", "exploring", event.target.checked)
                            }
                            id="stack-exploring" />
                        <label className="form-check-label" htmlFor="stack-exploring">
                            Exploring Apple Dev't
                                    </label>
                    </div>

                    <label>What do you do?</label>
                    <input 
                        type="text" 
                        name="Role"
                        placeholder="Role or title"
                        className="form-control mb-4"
                        value={this.state.role}
                        onChange={(event) =>
                            this.handleInputChange("role", event.target.value)
                        }
                    />
                    <input 
                        type="text" 
                        name="Organisation"
                        placeholder="School, company, or the name of your app*"
                        className="form-control mb-4"
                        value={this.state.organisation}
                        onChange={(event) =>
                            this.handleInputChange("organisation", event.target.value)
                        }
                    />

                    <label>Share at <i>least one</i> social profile</label>
                    <div className="mb-3 row">
                        <label htmlFor="twitter" className="col col-form-label social-icon">
                            <img alt="Twitter icon" src={twitterIcon} />
                        </label>
                        <div className="col">
                            <input
                                type="text"
                                className="form-control"
                                id="twitter"
                                placeholder="@username"
                                value={this.state.twitter}
                                onChange={(event) =>
                                    this.handleInputChange("twitter", event.target.value)
                                }
                                />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="facebook" className="col col-form-label social-icon">
                            <img alt="Facebook icon" src={facebookIcon} />
                        </label>
                        <div className="col">
                            <input
                                type="text"
                                className="form-control"
                                id="facebook"
                                placeholder="fb.com/appleseed"
                                value={this.state.facebook}
                                onChange={(event) =>
                                    this.handleInputChange("facebook", event.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="github" className="col col-form-label social-icon">
                            <img alt="GitHub icon" src={githubIcon} />
                        </label>
                        <div className="col">
                            <input
                                type="text"
                                className="form-control" 
                                id="github" 
                                placeholder="@username"
                                value={this.state.github} 
                                onChange={(event) =>
                                    this.handleInputChange("github", event.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="linked-in" className="col col-form-label social-icon">
                            <img alt="LinkedIn icon" src={linkedInIcon} />
                        </label>
                        <div className="col">
                            <input
                                type="text"
                                className="form-control"
                                id="linked-in"
                                placeholder="in/linkedinprofile"
                                value={this.state.linkedIn}
                                onChange={(event) =>
                                    this.handleInputChange("linkedIn", event.target.value)
                                }
                            
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="website" className="col col-form-label social-icon">
                            <img alt="Website icon" src={websiteIcon} />
                        </label>
                        <div className="col">
                            <input 
                                type="text"
                                className="form-control"
                                id="website"
                                placeholder="Website"
                                value={this.state.website}
                                onChange={(event) =>
                                    this.handleInputChange("website", event.target.value)
                                }
                            />
                        </div>
                    </div>

                    <label>Privacy Settings</label>
                    <div className="mb-3 row">
                        <label htmlFor="privacy" className="col-sm-6 col-form-label text-sm">Who can see your profile</label>
                        <div className="col-sm-6">
                            <select 
                                id="privacy"
                                className="form-control mb-1"
                                onChange={(event) =>
                                    this.handleInputChange("privacy", event.target.value)
                                }
                            >
                                <option value="anyone">Anyone</option>
                                <option value="attendees">Fellow attendees</option>
                                <option value="private">Just me</option>
                            </select>
                            <p className="text-cobble text-sm">
                                { this.privacyDescription() }
                            </p>
                        </div>
                    </div>

                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={ !this.inputsAreValid() }
                    >
                        Create Profile
                    </button>
                </form>
            </CreateProfileContainer>
        );
    }
}

export default CreateProfileScreen;
