import React from 'react';
import styled from "styled-components";
import { AccountContext } from 'Account/store';
import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import twitterIcon from '../components/icons/twitter.svg';
import facebookIcon from '../components/icons/fb.svg';
import githubIcon from '../components/icons/github.svg';
import linkedInIcon from '../components/icons/linkedin.svg';
import websiteIcon from '../components/icons/web.svg';
import EditProfileImage from './EditProfileImage';
import CountrySelect from 'components/CountrySelect';
import DashboardContainer, { DashboardInsideContainer } from 'components/DashboardContainer';

const EditProfileContainer = styled(DashboardInsideContainer)`
    padding-top:48px;
    padding-bottom:48px;

    form {
        padding:0px;
        max-width: 800px;
    }
    label {
        width:100%;
        color:#2C2929;
        text-align:left;
    }
    .social-icon {
        padding-top:6px;
        padding-bottom:6px;
        padding-right:0px;

        -ms-flex: 0 0 20px;
        flex: 0 0 20px;
    }
    .social-icon img {
        height:27px;
    }
    .btn {
       width:30%;
    }
`;


class ProfileScreen extends React.Component {
    static contextType = AccountContext;

    constructor(props) {
        super(props);
        this.editProfile = this.editProfile.bind(this);
        this.state = {
            loading: true,
            message: null,
            imageFile: null,
            firstName: "",
            lastName: "",
            pronouns: "",
            country: "",
            techStack: [ ],
            role: "",
            organisation: "",
            twitter: "",
            facebook: "",
            github: "",
            linkedIn: "",
            website: "",
            privacy: "anyone"
        };
    }

    componentDidMount() {
        this.getProfile()
    }

    getProfile() {
        let userID = this.context[0].user.uid;
        firebase
            .database()
            .ref('users/' + userID)
            .get()
            .then((snapshot) => {
                this.setProfile(snapshot.val())
                this.setState({ loading: false })
            })
    }
    setProfile(snapshot) {
        const definedSocials = this.defineSocials(snapshot.socials)
        this.setState({
            firstName: snapshot.firstName,
            lastName: snapshot.lastName,
            pronouns: snapshot.pronouns,
            country: snapshot.country,
            techStack: snapshot.techStack,
            role: snapshot.role ?? "",
            organisation: snapshot.organisation,
            imageURL: snapshot.imageURL,
            thumbnailURL: snapshot.thumbnailURL,
            socials: definedSocials,
            twitter: definedSocials.twitter,
            facebook: definedSocials.facebook,
            github: definedSocials.github,
            linkedIn: definedSocials.linkedIn,
            website: definedSocials.website,
            profile: snapshot,
        })
    }
    defineSocials(snapshot) {
        let newSnapshot = snapshot 
        newSnapshot.twitter = snapshot.twitter ? snapshot.twitter : ""
        newSnapshot.facebook = snapshot.facebook ? snapshot.facebook : ""
        newSnapshot.github = snapshot.github ? snapshot.github : ""
        newSnapshot.linkedIn = snapshot.linkedIn ? snapshot.linkedIn : ""
        newSnapshot.website = snapshot.website ? snapshot.website : ""
        return newSnapshot
    }


    editProfile(event) {
        event.preventDefault();

        var [state, dispatch] = this.context;
        if (!this.inputsAreValid() || !state.user) {
            return
        }
        
        var socials = {
            twitter: this.state.twitter,
            facebook: this.state.facebook,
            github: this.state.github,
            linkedIn: this.state.linkedIn,
            website: this.state.website
        }
        const userID = state.user.uid;
        const profile = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            emailAddress: state.user.email,
            pronouns: this.state.pronouns,
            country: this.state.country,
            techStack: this.state.techStack,
            imageURL: this.state.imageURL,
            thumbnailURL: this.state.thumbnailURL,
            role: this.state.role,
            organisation: this.state.organisation,
            socials: this.defineSocials(socials),
            privacy: this.state.privacy
        };

        this.setState({ loading: true });
        if (this.state.imageFile) {
            this.uploadImageToFirebase(userID, this.state.imageFile, (downloadURL, thumbnailURL) => {
                profile.imageURL = downloadURL;
                profile.thumbnailURL = thumbnailURL;

                this.uploadProfileToFirebase(userID, profile, () => {
                    dispatch({ type: "PROFILE_UPDATE", payload: profile });
                    this.setProfile(profile)
                });
            });
        } else {
            this.uploadProfileToFirebase(userID, profile, () => {
                dispatch({ type: "PROFILE_UPDATE", payload: profile });
                this.setProfile(profile)
            });
        }
    }

    uploadImageToFirebase(userID, image, completion) {
        const extension = image.name.split('.').pop();

        firebase
            .storage()
            .ref()
            .child("images/" + userID + "." + extension)
            .put(image)
            .then((snapshot) => {
                snapshot.ref.getDownloadURL().then((downloadURL) => {
                    firebase
                        .storage()
                        .ref()
                        .child("images/" + userID + "_200x200." + extension).getDownloadURL()
                        .then((thumbnailURL) => {
                            completion(downloadURL, thumbnailURL)
                        });                    
                })
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    message: error.message
                });
            })
    }

    uploadProfileToFirebase(userID, profile, completion) {
        firebase
            .database()
            .ref('users/' + userID)
            .set(profile)
            .then(() => {
                this.setState({ 
                    loading: false,
                    message: "Profile updated successfully.",
                });
                completion();
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    message: error.message
                });
            });
    }

    nullOrSet(value) {
        return value === "" ? null : value
    }

    atLeastOneSocialEntered() {
        return this.state.twitter !== "" ||
            this.state.facebook !== "" ||
            this.state.github !== "" ||
            this.state.linkedIn !== "" ||
            this.state.website !== ""
    }
    emptyStringAndUndefinedCheck(v1, v2) {
        if (v1 === "" && !v2) {
            return false
        }
        if (v2 === "" && !v1) {
            return false
        }
        return v1 !== v2
    }
    atLeastOneSocialDifferent() {
        if (!this.atLeastOneSocialEntered()) {
            return false
        }
        const socials = this.defineSocials({
            twitter: this.state.twitter,
            facebook: this.state.facebook,
            github: this.state.github,
            linkedIn: this.state.linkedIn,
            website: this.state.website
        })
        const profileSocials = this.defineSocials(this.state.profile.socials)
        return !Object.keys(socials).every((key) => socials[key] === profileSocials[key])

    }
    atLeastOneInputDifferent() {
        if (!this.state.profile) {
            return false
        }
        return this.state.firstName !== this.state.profile.firstName ||
        this.state.lastName !== this.state.profile.lastName ||
        this.state.pronouns !== this.state.profile.pronouns ||
        this.state.country !== this.state.profile.country ||
        this.state.techStack !== this.state.profile.techStack ||
        this.state.role !== this.state.profile.role ||
        this.state.organisation !== this.state.profile.organisation ||
        this.state.imageFile ||
        this.atLeastOneSocialDifferent()
    }

    inputsAreValid() {
        return this.state.firstName !== "" &&
            this.state.lastName !== "" &&
            this.state.pronouns !== "" &&
            this.state.country !== "" &&
            this.state.techStack.length > 0 &&
            this.state.organisation !== "" &&
            this.atLeastOneSocialEntered() &&
            this.atLeastOneInputDifferent() &&
            !this.state.loading;
    }

    handleInputChange(name, value) {
        this.setState({ [name]: value });
    }

    handleCheckboxChange(group, item, checked) {
        var value = this.state[group];
        if (checked) {
            value.push(item);
        } else {
            value = value.filter((x) => x !== item);
        }
        this.setState({ [group]: value });
    }

    handleImageSelection(file) {
        this.setState({ imageFile: file });
    }

    privacyDescription() {
        switch (this.state.privacy) {
            case "private":
                return (<span>Your profile is visible to you and <i>lead organisers</i> of WWDC21 Community Week.</span>);
            case "attendees":
                return "Your profile is only viewable on attendees dashboard."
            default:
                return "Your profile can be seen on our public attendees page.";
        }
    }

    render() {
        return (
            <DashboardContainer>
                <EditProfileContainer>
                    <h3>Your Profile</h3>
                    { this.state.message === "Profile updated successfully." &&
                        <div className="alert alert-success" role="alert">Profile updated successfully</div>
                    }
                    { this.state.message !== "Profile updated successfully." && this.state.message  &&
                        <div className="alert alert-danger" role="alert">{this.state.message}</div>
                    }
                    {!this.loading && 
                    <form onSubmit={this.editProfile}>
                        <div className="container row">
                            <div className="col-small">
                                <EditProfileImage imageSrc={this.state.imageURL} onChange={(file) => this.handleImageSelection(file)} />
                            </div>
                            <div align="center" className="col">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        name="firstName"
                                        id="first-name"
                                        placeholder="First name*"
                                        className="form-control mb-4"
                                        value={this.state.firstName}
                                        onChange={(event) =>
                                            this.handleInputChange("firstName", event.target.value)
                                        }
                                    />
                                    <input
                                        type="text"
                                        name="lastName"
                                        placeholder="Last name*"
                                        className="form-control mb-4"
                                        value={this.state.lastName}
                                        onChange={(event) =>
                                            this.handleInputChange("lastName", event.target.value)
                                        }
                                    />
                                </div>

                                <label htmlFor="pronouns">Pronouns</label>
                                <input
                                    type="text"
                                    id="pronouns"
                                    name="Pronouns"
                                    placeholder="i.e. she/her/hers*"
                                    className="form-control mb-4"
                                    value={this.state.pronouns}
                                    onChange={(event) =>
                                        this.handleInputChange("pronouns", event.target.value)
                                    }
                                />

                                <label>Country</label>
                                <CountrySelect value={this.state.country} onChange={(event) => {
                                    this.handleInputChange("country", event.target.selectedOptions[0].value)
                                }} />
                                <label>Apple tech stack</label><br />
                                <p className="text-sm text-cobble text-left">Select at least one</p>

                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={this.state.techStack.indexOf("ios") !== -1}
                                        onChange={(event) =>
                                            this.handleCheckboxChange("techStack", "ios", event.target.checked)
                                        }
                                        id="stack-ios" />
                                    <label className="form-check-label" htmlFor="stack-ios">
                                        iOS
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={this.state.techStack.indexOf("macos") !== -1}
                                        onChange={(event) =>
                                            this.handleCheckboxChange("techStack", "macos", event.target.checked)
                                        }
                                        id="stack-macos" />
                                    <label className="form-check-label" htmlFor="stack-macos">
                                        macOS
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={this.state.techStack.indexOf("tvos") !== -1}
                                        onChange={(event) =>
                                            this.handleCheckboxChange("techStack", "tvos", event.target.checked)
                                        }
                                        id="stack-tvos" />
                                    <label className="form-check-label" htmlFor="stack-tvos">
                                        tvOS
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={this.state.techStack.indexOf("watchos") !== -1}
                                        onChange={(event) =>
                                            this.handleCheckboxChange("techStack", "watchos", event.target.checked)
                                        }
                                        id="stack-watchos" />
                                    <label className="form-check-label" htmlFor="stack-watchos">
                                        watchOS
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={this.state.techStack.indexOf("server") !== -1}
                                        onChange={(event) =>
                                            this.handleCheckboxChange("techStack", "server", event.target.checked)
                                        }
                                        id="stack-server" />
                                    <label className="form-check-label" htmlFor="stack-server">
                                        Server-side Swift
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={this.state.techStack.indexOf("beginner") !== -1}
                                        onChange={(event) =>
                                            this.handleCheckboxChange("techStack", "beginner", event.target.checked)
                                        }
                                        id="stack-beginner" />
                                    <label className="form-check-label" htmlFor="stack-beginner">
                                        Beginning to Learn Apple Dev't
                                    </label>
                                </div>
                                <div className="form-check mb-4">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={this.state.techStack.indexOf("exploring") !== -1}
                                        onChange={(event) =>
                                            this.handleCheckboxChange("techStack", "exploring", event.target.checked)
                                        }
                                        id="stack-exploring" />
                                    <label className="form-check-label" htmlFor="stack-exploring">
                                        Exploring Apple Dev't
                                    </label>
                                </div>

                                <label>What do you do?</label>
                                <input
                                    type="text"
                                    name="Role"
                                    placeholder="Role or title"
                                    className="form-control mb-4"
                                    value={this.state.role}
                                    onChange={(event) =>
                                        this.handleInputChange("role", event.target.value)
                                    }
                                />
                                <input
                                    type="text"
                                    name="Organisation"
                                    placeholder="School, company, or the name of your app*"
                                    className="form-control mb-4"
                                    value={this.state.organisation}
                                    onChange={(event) =>
                                        this.handleInputChange("organisation", event.target.value)
                                    }
                                />

                                <label>Share at <i>least one</i> social profile</label>
                                <div className="mb-3 row">
                                    <label htmlFor="twitter" className="col col-form-label social-icon">
                                        <img alt="Twitter icon" src={twitterIcon} />
                                    </label>
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="twitter"
                                            placeholder="@username"
                                            value={this.state.twitter }
                                            onChange={(event) =>
                                                this.handleInputChange("twitter", event.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor="facebook" className="col col-form-label social-icon">
                                        <img alt="Facebook icon" src={facebookIcon} />
                                    </label>
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="facebook"
                                            placeholder="fb.com/appleseed"
                                            value={this.state.facebook }
                                            onChange={(event) =>
                                                this.handleInputChange("facebook", event.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor="github" className="col col-form-label social-icon">
                                        <img alt="GitHub icon" src={githubIcon} />
                                    </label>
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="github"
                                            placeholder="@username"
                                            value={this.state.github }
                                            onChange={(event) =>
                                                this.handleInputChange("github", event.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor="linked-in" className="col col-form-label social-icon">
                                        <img alt="LinkedIn icon" src={linkedInIcon} />
                                    </label>
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="linked-in"
                                            placeholder="in/linkedinprofile"
                                            value={this.state.linkedIn }
                                            onChange={(event) =>
                                                this.handleInputChange("linkedIn", event.target.value)
                                            }

                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor="website" className="col col-form-label social-icon">
                                        <img alt="Website icon" src={websiteIcon} />
                                    </label>
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="website"
                                            placeholder="Website"
                                            value={this.state.website }
                                            onChange={(event) =>
                                                this.handleInputChange("website", event.target.value)
                                            }
                                        />
                                    </div>
                                </div>

                                <label>Privacy Settings</label>
                                <div className="mb-3 row">
                                    <label htmlFor="privacy" className="col-sm-6 col-form-label text-sm">Who can see your profile</label>
                                    <div className="col-sm-6">
                                        <select
                                            id="privacy"
                                            className="form-control mb-1"
                                            onChange={(event) =>
                                                this.handleInputChange("privacy", event.target.value)
                                            }
                                        >
                                            <option value="anyone">Anyone</option>
                                            <option value="attendees">Fellow attendees</option>
                                            <option value="private">Just me</option>
                                        </select>
                                        <p className="text-cobble text-sm">
                                            {this.privacyDescription()}
                                        </p>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={!this.inputsAreValid()}
                                >
                                    Update Profile
                                </button>
                            </div>

                        </div>
                    </form>
                }
                </EditProfileContainer>
            </DashboardContainer>
        );
    }
}

export default ProfileScreen;
