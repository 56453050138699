import { useEffect, useState } from "react";
import firebase from "firebase/app";
import styled from "styled-components";
import { useContext } from "react";
import { AccountContext } from "Account/store";
import { Redirect } from "react-router-dom";
import { getNestedData } from "components/helpers";

const DownloadDiv = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 40px;
  width: 474px;
  margin: 80px auto;
  text-align: center;
  color: black;
`;

function EventsDownloadScreen() {
  const [state] = useContext(AccountContext);
  const [event, setEvent] = useState("");
  const [profilesCount, setProfilesCount] = useState(0);

  useEffect(() => {
    firebase
      .database()
      .ref("users")
      .get()
      .then((snapshot) => {
        setProfilesCount(Object.keys(snapshot.val()).length);
      })
      .catch((error) => {
        console.error(error);
      });

    firebase
      .database()
      .ref("users")
      .get()
      .then((snapshot) => {
        setProfilesCount(Object.keys(snapshot.val()).length);
      })
      .catch((error) => {
        console.error(error);
      });
  });

  function downloadAttendees() {
    const dbRef = firebase.database().ref(`eventGuests/${event}`);
    getNestedData(dbRef, "users").then((value) => {
      let emails = value
        .filter((n) => n)
        .map((user) => [
          user.emailAddress,
          `${user.firstName} ${user.lastName}`,
          "",
          `${[user.role, user.organisation]
            .filter((value) => value !== "")
            .join(" ")}`,
        ]);

      emails.unshift(["email", "name", "role", "affiliation"]);
      buildCsv(emails, `${event}.csv`);
    });
  }

  function downloadRsvps() {
    const dbRef = firebase.database().ref(`eventAttendees/${event}`);
    getNestedData(dbRef, "users").then((value) => {
      let emails = value
        .filter((n) => n)
        .map((user) => [user.emailAddress, user.firstName]);

      emails.unshift(["email", "first_name"]);
      buildCsv(emails, `${event}.csv`);
    });
  }

  function downloadUsers() {
    firebase
      .database()
      .ref("users")
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          let users = snapshot.val();
          const userList = Object.keys(users).map((userId) => {
            const user = users[userId];
            return [
              user.emailAddress,
              `${user.firstName} ${user.lastName}`,
              "",
              `${[user.role, user.organisation]
                .filter((value) => value !== "")
                .join(" ")}`,
            ];
          });
          userList.unshift(["email", "name", "role", "affiliation"]);
          buildCsv(userList, `all_users.csv`);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function buildCsv(csvList, filename) {
    let csvContent =
      "data:text/csv;charset=utf-8," +
      csvList.map((e) => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }

  if (state.user && state.user.isAdmin) {
    return (
      <DownloadDiv>
        <div>Number of profiles created: {profilesCount}</div>
        <hr />
        <div className="text-xl text-weight-600 text-pink mb-3">
          Download emails
        </div>
        <input
          value={event}
          className="form-control"
          name="event"
          id="event"
          placeholder="Type event id"
          onChange={(e) => setEvent(e.target.value)}
        />
        <button
          className="btn btn-primary text-weight-500 mt-3 mb-3"
          onClick={downloadAttendees}
        >
          Download Attendees
        </button>
        <button
          className="btn btn-primary text-weight-500 mt-3 mb-3 ml-2"
          onClick={downloadRsvps}
        >
          Download RSVPs
        </button>
        <hr />
        <div className="text-xl text-weight-600 text-pink mb-2">
          Download all users
        </div>
        <div>
          <button
            className="btn btn-primary text-weight-500 mb-2"
            onClick={downloadUsers}
          >
            Download Users
          </button>
        </div>
      </DownloadDiv>
    );
  } else {
    return <Redirect to="/dashboard" />;
  }
}

export default EventsDownloadScreen;
