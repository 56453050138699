import React, { useState, useEffect } from 'react'
import { SponsorHeading, SponsorLogo } from '../SplashScreen/styles';

const SponsorFetch = () => {

    const [sponsorData, setSponsorData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetch("https://api.airtable.com/v0/appZGpRTkEij893tS/Sponsors?maxRecords=50&view=Grid+view", {
            "headers": {
                "authorization": "Bearer keyNfd02JL9fMEUlx",
                "x-airtable-application-id": "appZGpRTkEij893tS",
                "content-type": "application/json",
                "x-airtable-user-agent": "Airtable.js/undefined"
            },
            "method": "GET",
            "mode": "cors"
        })
            .then(res => res.json())
            .then(json => {
                setSponsorData(json.records)
                setLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setLoading(false)
            })
    }, [])
    const SponsorColors = {
        Platinum: "#48C64D",
        Gold: "#FFD850",
        Bronze: "#F8F6F0"
    }
    return(
        <>
        {!loading &&
            sponsorData.map((data, index) => (
                <>
                <div className="row mb-4" key={index}>
                    <div className="col"></div>
                        <div className="col-sm-7">
                            <SponsorHeading style={{color: SponsorColors[data.fields.type]}}>{data.fields.type}</SponsorHeading>
                            <a href={data.fields.website_url} target="_blank" rel="noreferrer">
                                <SponsorLogo src={data.fields.for_dark_background_url} alt="Sponsor Logo"/>
                            </a>
                            <p style={{ textAlign: "center" }}>
                                {(data.fields.type === 'Bronze' ? '' : data.fields.description)}
                            </p>
                            {data.fields.job_text &&                            
                                <a href={data.fields.job_url} target="_blank" rel="noreferrer" style={{ color: "#48C64D", textAlign: "center" }}>{data.fields.job_text}</a>
                            }
                        </div>
                    <div className="col"></div>
                </div>
                </>
            ))
        }
        </>
    )
}

export default SponsorFetch