import React from 'react'
import styled from "styled-components";
import ProfileSocialLinks from './ProfileSocialLinks';

const VolunteerFlex = styled.div`
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    background: white;
    box-shadow: 0px 4px 17px rgba(103, 94, 94, 0.125);
    transition: box-shadow 0.8s ease;
    :hover {
        color: #2c2929;
        box-shadow: 0px 10px 20px rgba(103, 94, 94, 0.25);
    }
`
const VolunteerBoxBorder = styled.div`
    background: #2BE3CD;
    margin-right: 0px;
    width: 14px;
`

const VolunteerContent = styled.div`
    display: flex;
    width: 95%;
    flex-direction: column;
    margin-left: calc(10px + 2vw);
    margin-top: 14px;
`

const VolunteerTeam = styled.p`
    margin-top: calc(10px + 0.5vw);
    font-weight: 600;
    font-size: calc(15px + 0.5vw);
    line-height: 28px;
    color: #2C2929;
`

const Volunteer = styled.div`
    display:grid;
    grid-template-columns: 80px 1fr;
    grid-column-gap: 30px;

    img.profile-image {
        width:80px;
        height:80px;
        border-radius:40px;
        border: 2.5px solid;
    }

    h1 {
        margin:0px;
        margin-right: 20px;
    }

    * {
        display:inline;
    }

    p {
        display:block;
    }
`

const VolunteerPreview = ({backgroundColor, team, volunteers}) => {
    const displayVolunteer = (volunteer, index) => {
        return <Volunteer key={index}>
            <img 
                className="profile-image"
                src={volunteer.imageURL} 
                style={{borderColor: backgroundColor}}
                loading="lazy"
                aria-hidden 
                alt={`${volunteer.name} Profile Picture`} />
            <div>
                <h1 className="text-weight-500 text-xxxl">{volunteer.name}</h1>
                <ProfileSocialLinks style={{display:'inline'}} socials={volunteer.socials} />
                <p className="text-cobble">{volunteer.role}</p>
            </div>
            <hr/>
        </Volunteer>
    }

    return(
        <VolunteerFlex>
            <VolunteerBoxBorder style={{ background: backgroundColor }}/>
            <VolunteerContent>
                <VolunteerTeam>{team}</VolunteerTeam>
                { volunteers.map(displayVolunteer) }
            </VolunteerContent>
        </VolunteerFlex>
    )
}

export default VolunteerPreview