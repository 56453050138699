import React from 'react'
import styled from "styled-components";

const CommunityHeroFlex = styled.a`
    width: 100%;
    height: 159px;
    box-shadow: 0px 4px 17px rgba(103, 94, 94, 0.125);
    color: #2c2929;
    transition: box-shadow 0.8s ease;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    :hover {
        color: #2c2929;
        box-shadow: 0px 10px 20px rgba(103, 94, 94, 0.25);
    }
`
const CommunityHeroTopBar = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 47px;
`
const CommunityHeroBottom = styled.div`
    display: flex;
    align-items: center;
    height: 112px;
`

const CommunityHeroImage = styled.img`
    max-width: 80px;
    border-radius: 100%;
    position: absolute;
`

const CommunityHeroCountry = styled.p`
    position: relative;
    font-size: 24px;
    margin-top: 48px;
    margin-left: 56px;
`

const BadgeName = styled.p`
    margin: 0px;
    margin-left: 18px;
    text-align: left;
    font-size: calc(10px + 0.5vw);
    @media only screen and (max-width: 600px) {
        font-size: calc(10px + 1vw);
        text-align: left;
        margin-left: 24px;
    }
`

const HeroBottomDivTwo = styled.div`
    width: 30%;
    height: 80px;
`
const HeroBottomDivOne = styled.div`
    width: 70%;
    @media only screen and (max-width: 1280px) {
        width: 60%
    }
    @media only screen and (max-width: 600px) {
        width: 70%
    }
`
const BadgesDiv = styled.div`
    margin-left: 18px;
    @media only screen and (max-width: 600px) {
        margin-left: 24px;
    }
`

const CommunityHeroName = styled.p`
    font-weight: 500;
    font-size: 20px;
    color: #2C2929;
    margin: 0px;
    margin-left: 20px;
`

const CommunityBarColors = [
    "#2BE3CD",
    "#FFEA2E",
    "#36B6FF",
    "#FF4768",
    "#FF8C38",
];

const CommunityHeroPreview = ({ name, index, image, country, url, badges }) => {
    return(
        <>
            <CommunityHeroFlex href={url}>
                <CommunityHeroTopBar style={{ background: CommunityBarColors[index % 5] }}>
                    <CommunityHeroName>{name}</CommunityHeroName>
                </CommunityHeroTopBar>
                <CommunityHeroBottom>
                    <HeroBottomDivOne>
                        <BadgeName>Community Badges</BadgeName>
                        <BadgesDiv>
                            {
                                badges.map((index) => (
                                    <img alt="Badge" src="https://dl.airtable.com/.attachmentThumbnails/9dc64e58d072119801d1ef3852e1a251/8757f76d" />
                                ))
                            }
                        </BadgesDiv>

                    </HeroBottomDivOne>
                    <HeroBottomDivTwo>
                        <CommunityHeroImage style={{ border: `2px solid ${CommunityBarColors[index % 5]}`}} alt="Profile Picture" src={image}></CommunityHeroImage>
                        <CommunityHeroCountry>{country}</CommunityHeroCountry>
                    </HeroBottomDivTwo>
                </CommunityHeroBottom>
            </CommunityHeroFlex>
        </>
    )
}

export default CommunityHeroPreview
