import twitterIcon from "components/icons/twitter.svg";
import facebookIcon from "components/icons/fb.svg";
import githubIcon from "components/icons/github.svg";
import linkedInIcon from "components/icons/linkedin.svg";
import websiteIcon from "components/icons/web.svg";
import styled from "styled-components";

const SocialImage = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-right: 8px;
`;

const SocialLink = styled.a`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  font-weight: 300;
  margin-bottom: 12px;
`;

export const ProfileSocialLink = ({ type, social, inline }) => {
  const links = {
    twitter: {
      link: "https://twitter.com/",
      image: twitterIcon,
    },
    facebook: {
      link: "https://facebook.com/",
      image: facebookIcon,
    },
    github: {
      link: "https://github.com/",
      image: githubIcon,
    },
    linkedIn: {
      link: "https://linkedin.com/",
      image: linkedInIcon,
    },
    website: {
      link: "",
      image: websiteIcon,
    },
  };

  const link = links[type].link;
  const image = links[type].image;

  let socialLink = social;
  if (social.startsWith("@")) {
    socialLink = socialLink.replace("@", "");
  }
  if (social.startsWith("https://")) {
    socialLink = socialLink.replace("https://", "");
  }

  return (
    <SocialLink
      href={`${link.startsWith("https://") ? link : "https://"}${socialLink}`}
      style={{
        display: inline ? "inline" : "block",
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <SocialImage src={image} alt={type} />
      {!inline && social}
    </SocialLink>
  );
};

const ProfileSocialLinks = ({ socials }) => {
  if (socials) {
    const socialKeys = Object.keys(socials);

    return (
      <div>
        {socialKeys.map((key) => {
          if (socials[key]) {
            return (
              <ProfileSocialLink
                key={key}
                type={key}
                inline={true}
                social={socials[key]}
              />
            );
          }
          return null;
        })}
      </div>
    );
  }

  return null;
};

export default ProfileSocialLinks;
