import React, { useEffect, useState } from 'react'
import DashboardContainer, {
  DashboardInsideContainer,
} from "components/DashboardContainer";
import styled from "styled-components";
import { EventLoadingPreview } from "components/EventPreview";


const SponsorsSubtitle = styled.div`
  @media only screen and (min-width: 800px) {
    max-width: 60%;
  }
`;

const SponsorsGroup = styled.div`
  margin-top: 100px;

  @media only screen and (max-width: 800px) {
    margin-top: 60px;
  }
`;

const SponsorEventFlex = styled.div`
  display: grid;
  grid-template-columns: calc(50px + 1vw) 1fr 1fr;
  align-items: center;
  min-height: 128px;
`;

const SponsorEventDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2BE3CD;
  width: calc(50px + 1vw);
  height: calc(50px + 1vw);
`;

const SponsorsScreen = () => {
  const [loading, setLoading] = useState(true)
  const [sponsors, setSponsors] = useState([])

  useEffect(() => {
    fetch("https://api.airtable.com/v0/appZGpRTkEij893tS/Sponsors?maxRecords=50&view=Grid+view", {
        "headers": {
            "authorization": "Bearer keyNfd02JL9fMEUlx",
            "x-airtable-application-id": "appZGpRTkEij893tS",
            "content-type": "application/json",
            "x-airtable-user-agent": "Airtable.js/undefined"
        },
        "method": "GET",
        "mode": "cors"
    })
        .then(res => res.json())
        .then(json => {
            setSponsors(json.records)
            setLoading(false)
        })
        .catch((err) => {
            console.error(err)
            setLoading(false)
        })
    }, [])

  return (
    <DashboardContainer>
      <DashboardInsideContainer className="my-5">
        <h1 className="text-xxxl text-weight-600 mb-4">Sponsors</h1>
        <SponsorsSubtitle className="text-xxl text-weight-500 mr-md-5">
          We would like to thank all our sponsors in helping us make this event
          happen.
        </SponsorsSubtitle>

        {loading &&
          [1, 2, 3, 4, 5, 6].map((index) => (
            <EventLoadingPreview key={index} />
          ))
        }

        {!loading &&
        
        sponsors.map((data, index) => (
          <SponsorsGroup key={index} style={{ marginTop: '50px'}}>

            <h3 className="text-xxxl text-weight-600 text-capitalize">{data.fields.type}</h3>
            
            <div
              className="d-flex flex-column flex-md-row mt-4"
              key={data.fields.company_name}
            >
              <a
                href={data.fields.website_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={data.fields.for_light_background_url}
                  alt={data.fields.company_name}
                  width="250px"
                  height="auto"
                  loading="lazy"
                />
              </a>

              <div className="text-weight-500 mt-3 ml-md-5" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                {data.fields.description}
                <a target="_blank" rel="noreferrer" href={data.fields.job_url} style={{ marginTop: data.fields.description ? '15px' : ''}} >
                    {data.fields.job_text}
                </a>

                {data.fields.company_name === 'GoodNotes' &&
                  <SponsorEventFlex>
                    <SponsorEventDate>
                      <p style={
                        { textAlign: 'center', margin: '0px', fontWeight: '600', fontSize: 'calc(12px + 0.4vw)'}
                      }>Jun <br /> 6</p>
                    </SponsorEventDate>
                    <div>
                      <p style={{ color: '#677E9A', marginLeft: '20px', marginBottom: '0px', fontSize: 'calc(8px + 0.5vw)' }}>Sponsor's Event</p>
                      <p style={{ fontWeight: '600', color: '#2C2929', fontSize: 'calc(11px + 0.6vw)', marginLeft: '20px', marginBottom: '0px' }}>Sketchnoting with GoodNotes</p>
                      <p style={{ fontWeight: '600', color: '#2C2929', fontSize: 'calc(8px + 0.5vw)', marginLeft: '20px', marginBottom: '0px' }}>02:00 PM - 03:00 PM (UTC)</p>
                    </div>
                    <div>
                      <a href="/events/sketchnoting-with-goodnotes" className="btn btn-primary px-5 py-2 text-weight-500 mb-2">
                        RSVP
                      </a>
                    </div>
                  </SponsorEventFlex>
                }
              </div>
            </div>
            

          </SponsorsGroup>
        ))}

      </DashboardInsideContainer>
    </DashboardContainer>
  );
};

export default SponsorsScreen;
