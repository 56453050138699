import React from 'react'
import styled from "styled-components";

const JobFlex = styled.a`
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    min-height: 200px;
    width: 100%;
    background: white;
    box-shadow: 0px 4px 17px rgba(103, 94, 94, 0.125);
    transition: box-shadow 0.8s ease;
    :hover {
        color: #2c2929;
        box-shadow: 0px 10px 20px rgba(103, 94, 94, 0.25);
    }
`
const JobBoxBorder = styled.div`
    height: 100%;
    background: #2BE3CD;
    margin-right: 0px;
    width: 14px;
`

const JobContent = styled.div`
    display: flex;
    width: 95%;
    flex-direction: column;
    margin-left: calc(10px + 2vw);
    margin-top: 14px;
`

const JobSponsorLogo = styled.img`
    max-width: calc(150px + 3vw);
`
const JobSponsorLogoDiv = styled.div`
    display: flex;
    align-items: center;
`
const PlatinumText = styled.p`
    font-weight: 600;
    font-size: calc(10px + 0.4vw);
    line-height: 19px;
    text-transform: uppercase;
    color: #677E9A;
    margin: 0px;
    margin-left: 10px;
`
const JobTitle = styled.p`
    font-weight: 500;
    font-size: calc(15px + 0.5vw);
    line-height: 28px;
    color: #2C2929;
`

const JobCompanyName = styled.p`
    font-weight: 500;
    font-size: calc(12px + 0.35vw);
    line-height: 21px;
    color: #1756FA;
`

const JobDescription = styled.div`
    font-weight: normal;
    font-size: calc(8px + 0.4vw);
    max-width: 90%;
    line-height: calc(15px + 0.5vw);
    color: #292929;
`
const ApplyText = styled.p`
    font-weight: 500;
    font-size: calc(14px + 0.4vw);
    line-height: 23px;
    color: #1756FA;
    text-align: right;
    width: 94%;
    margin-bottom: 25px;
    margin-top: calc(10px + 0.3vw)
`

const JobBorderColors = [
    "#2BE3CD",
    "#FFEA2E",
    "#36B6FF",
    "#FF4768",
    "#FF8C38",
];

const JobPreview = ( { sponsorType, logo, url, title, company, description, index } ) => {
    return(
        <JobFlex href={url} target="_blank" rel="noopener noreferrer" style={{ cursor: url ? 'pointer' : 'default'}}>
            <JobBoxBorder style={{ background: JobBorderColors[index % 5] }}/>
            <JobContent>

                <JobSponsorLogoDiv style={{ marginTop: description ? '' : '20px' }}>
                    <JobSponsorLogo src={logo} alt="Sponsor Logo"></JobSponsorLogo>
                    <PlatinumText>{sponsorType}</PlatinumText>
                </JobSponsorLogoDiv>

                <JobTitle style={{ marginTop: description ? '' : '20px' }}>{title}</JobTitle>
                <JobCompanyName>{company}</JobCompanyName>

                {description && 
                    <JobDescription>{description}</JobDescription>
                }

                {url && 
                    <ApplyText>APPLY</ApplyText>
                }
            </JobContent>
        </JobFlex>
    )
}

export default JobPreview