import React, { useState, useEffect } from 'react'
import DashboardContainer, {
    DashboardInsideContainer,
  } from "components/DashboardContainer";
import styled from "styled-components";
import JobPreview from "components/JobPreview"
import { EventLoadingPreview } from "components/EventPreview";


const JobBoardGrid = styled.div`
  display: grid;
  grid-templates-row: 1fr;
  grid-row-gap: 30px;
  margin-bottom: 100px;
`

const JobBoard = () => {
    const [jobData, setJobData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetch("https://api.airtable.com/v0/appZGpRTkEij893tS/Job%20Board?maxRecords=50&view=Grid+view", {
            "headers": {
                "authorization": "Bearer keyNfd02JL9fMEUlx",
                "x-airtable-application-id": "appZGpRTkEij893tS",
                "content-type": "application/json",
                "x-airtable-user-agent": "Airtable.js/undefined"
            },
            "method": "GET",
            "mode": "cors"
        })
            .then(res => res.json())
            .then(json => {
                setJobData(json.records)
                setLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setLoading(false)
            })
    }, [])

    return (
        <>
            <DashboardContainer>
                <DashboardInsideContainer className="mt-5">
                    <div className="d-flex align-items-center justify-content-between mb-5">
                        <h1 className="text-xxxl text-weight-600 mb-0">Job Board</h1>
                        <a
                            href="https://ko-fi.com/s/0b6a882c50"
                            className="btn btn-primary px-4 py-2 text-weight-500"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Submit a Job Post
                        </a>
                    </div>
                    <JobBoardGrid>

                        {loading &&
                            [1, 2, 3, 4, 5, 6].map((index) => (
                            <EventLoadingPreview key={index} />
                        ))}

                        {!loading &&

                            jobData.map((data, index) => (
                                <JobPreview 
                                    url={data.fields.job_link}
                                    logo={data.fields.sponsor_logo_url}
                                    title={data.fields.job_title}
                                    company={data.fields.company_name}
                                    sponsorType={data.fields.sponsor_type}
                                    description={data.fields.job_description}
                                    index={index}
                                />
                            ))

                        }

                    </JobBoardGrid>
                </DashboardInsideContainer>
            </DashboardContainer>
        </>
    )
}

export default JobBoard
