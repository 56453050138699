import { useContext, useState } from "react";
import { AccountContext } from "Account/store";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import classNames from "classnames";
import DashboardSidebar from "./DashboardSidebar";

// Imported for the right side of the dashboard
export const DashboardInsideContainer = styled.div`
  max-width: 1500px;
  padding: 0 3rem;
  @media only screen and (max-width: 991px) {
    padding: 0 1.5rem;
  }
`;

const SidebarContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: #0d0426;
  width: 100%;
  max-width: 100%;
  padding: 0 30px;

  @media only screen and (min-width: 990px) {
    flex: 0 0 300px;
    max-width: 300px;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  @media only screen and (max-width: 991px) {
    height: 100vh;
    overflow-y: scroll;
    padding-top: 70px;
    padding-bottom: 150px;
    z-index: 3;

    @supports (-webkit-touch-callout: none) {
      min-height: -webkit-fill-available;
    }
  }
`;

const MainContainer = styled.div`
  background-color: white;
  color: black;
  padding-bottom: 100px;

  @media only screen and (min-width: 991px) {
    flex: 0 0 calc(100% - 300px);
    max-width: calc(100% - 300px);
  }

  @media only screen and (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const CloseBtn = styled.div`
  cursor: pointer;
  padding: 15px;
  position: absolute;
  left: 10px;
  top: 10px;
`;

const DashboardHeader = styled.div`
  padding: 0 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 991px) {
    height: 55px;
    border-bottom: 1px solid #f3f3f3;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;

    img {
      max-width: 70%;
    }
  }
`;

const DashboardMenu = styled.div`
  cursor: pointer;
  position: absolute;
  left: 2%;
  padding: 15px;
`;

const DashboardMainContent = styled.div`
  padding: 2.5rem 0;
  display: unset;

  @media only screen and (max-width: 991px) {
    padding: 2rem 0;
  }
`;

const DashboardContainer = ({ children }) => {
  const [state] = useContext(AccountContext);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  if (state.user && !state.profile) {
    return <Redirect to="/create-profile" />;
  }

  return (
    <div className="row no-gutters">
      <SidebarContainer
        className={classNames("d-lg-block", {
          "d-block": isSidebarOpen,
          "d-none": !isSidebarOpen,
        })}
      >
        <CloseBtn className="d-lg-none" onClick={toggleSidebar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="white"
              d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
            />
          </svg>
        </CloseBtn>
        <DashboardSidebar />
      </SidebarContainer>
      <MainContainer>
        <DashboardHeader>
          <DashboardMenu
            className="d-flex d-lg-none menu"
            onClick={toggleSidebar}
          >
            <svg
              width="17"
              height="14"
              viewBox="0 0 17 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.579449 1.16667H15.6451C15.9651 1.16667 16.2246 0.905499 16.2246 0.583333C16.2246 0.261167 15.9651 0 15.6451 0H0.579449C0.259428 0 0 0.261167 0 0.583333C0 0.905499 0.259428 1.16667 0.579449 1.16667ZM16.2246 7C16.2246 7.32217 15.9651 7.58333 15.6451 7.58333H0.579449C0.259428 7.58333 0 7.32217 0 7C0 6.67783 0.259428 6.41667 0.579449 6.41667H15.6451C15.9651 6.41667 16.2246 6.67783 16.2246 7ZM0.579449 12.8333H15.6451C15.9651 12.8333 16.2246 13.0945 16.2246 13.4167C16.2246 13.7388 15.9651 14 15.6451 14H0.579449C0.259428 14 0 13.7388 0 13.4167C0 13.0945 0.259428 12.8333 0.579449 12.8333Z"
                fill="#304866"
              />
            </svg>
          </DashboardMenu>
          <strong className="d-block d-lg-none">WWDC Community Week</strong>
        </DashboardHeader>
        <DashboardMainContent>{children}</DashboardMainContent>
      </MainContainer>
    </div>
  );
};

export default DashboardContainer;
