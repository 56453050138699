import styled from "styled-components";

const LoadingContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loading = styled.div`
  display: inline-block;
  position: relative;
  width: 180px;
  height: 180px;

  div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 81px;
      left: 81px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 162px;
      height: 162px;
      opacity: 0;
    }
  }
`;

const MainLoadingContainer = () => (
  <LoadingContainer>
    <Loading>
      <div />
      <div />
    </Loading>
  </LoadingContainer>
);

export default MainLoadingContainer;
