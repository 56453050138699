import React, { useState, useEffect } from 'react'
import DashboardContainer, {
    DashboardInsideContainer,
} from "components/DashboardContainer";
import styled from "styled-components";
import { EventLoadingPreview } from "components/EventPreview";

const AppBoardGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
    margin-bottom: 200px;
`;

const AppFlexParent = styled.a`
    display: flex;
    align-items: flex-start;
`;

const AppLogo = styled.img`
    width: calc(50px + 4vw);
    border-radius: calc(10px + 1vw);
`

const AppContent = styled.div`
    display: flex;
    width: 90%;
    margin-left: calc(12px + 1vw);
    flex-direction: column;
    align-items: flex-start;
`;

const AppName = styled.p`
    font-size: calc(16px + 0.4vw);
    font-weight: 600;
    color: #2C2929;
    margin-bottom: calc(2px + 0.1vw);
`

const DeveloperName = styled.p`
    font-size: calc(12px + 0.25vw);
    font-weight: 400;
    color: #2C2929;
    margin-bottom: calc(2px + 0.1vw);
`;

const AppBottomBorder = styled.div`
    height: 1px;
    width: 100%;
    background: #E1DDDD;
`;

const AppDescription = styled.p`
    font-size: calc(12px + 0.25vw);
    font-weight: 400;
    color: #677E9A;
`;

const AppNameFlex = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`

const PromoCodeAvailable = styled.p`
    color: rgba(251, 12, 98, 1);
    font-size: calc(10px + 0.3vw);
`;



const AppBoard = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])

    useEffect(() => {
        fetch("https://api.airtable.com/v0/appZGpRTkEij893tS/Apps?maxRecords=500&view=Grid+view&offset=", {
            "headers": {
                "authorization": "Bearer keyNfd02JL9fMEUlx",
            },
            "method": "GET",
            "mode": "cors"
        })
            .then(res => res.json())
            .then(json => {
                fetch(`https://api.airtable.com/v0/appZGpRTkEij893tS/Apps?maxRecords=500&view=Grid+view&offset=${json.offset}`, {
                    "headers": {
                        "authorization": "Bearer keyNfd02JL9fMEUlx",
                    },
                    "method": "GET",
                    "mode": "cors"
                })
                .then(res => res.json())
                .then(secJson => {
                    let initialData = json.records.map((record) => record.fields)
                    let secondaryData = secJson.records.map((record) => record.fields)
                    let effectiveData = []
                    initialData.forEach((element) => effectiveData.push(element))
                    secondaryData.forEach((element) => effectiveData.push(element))
                    setData(effectiveData)
                    setLoading(false)
                })
                .catch((err) => {
                    console.error(err)
                    setLoading(false)
                })
            })
            .catch((err) => {
                console.error(err)
                setLoading(false)
            })
    }, [])

    return (
        <DashboardContainer>
            <DashboardInsideContainer className="mt-5">
                <h1 className="text-xxxl text-weight-600 mb-4">App Board</h1>
                <AppBoardGrid>

                {loading &&
                    [1, 2, 3, 4, 5, 6].map((index) => (
                    <EventLoadingPreview key={index} />
                    ))
                }

                {!loading &&    
                    data.map((app, index) => (
                        <>
                        <AppFlexParent key={index} href={app.app_url} target="_blank" rel="noopener noreferrer">
                            <AppLogo alt="App Logo" loading="lazy" src={app.app_logo_url} />
                            <AppContent>
                                <AppNameFlex>
                                    <AppName>{app.app_name}</AppName>
                                    <PromoCodeAvailable>{app.has_promo_code ? 'Exclusive promo code available ✨' : ''}</PromoCodeAvailable>
                                </AppNameFlex>
                                <DeveloperName>{app.developer_name}</DeveloperName>
                                <AppDescription>{app.description}</AppDescription>
                            </AppContent>
                        </AppFlexParent>
                        <AppBottomBorder></AppBottomBorder>
                        </>
                    ))
                }

                </AppBoardGrid>
            </DashboardInsideContainer>
        </DashboardContainer>
    )
}

export default AppBoard
