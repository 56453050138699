import React, {createContext, useReducer} from "react";
import AccountReducer from "./reducer";

const initialState = {
    user: null,
    profile: null
};

const AccountStore = ({children}) => {
    const [state, dispatch] = useReducer(AccountReducer, initialState);
    return (
        <AccountContext.Provider value={[state, dispatch]}>
            {children}
        </AccountContext.Provider>
    )
};

export const AccountContext = createContext(initialState);
export default AccountStore;