import { DashboardInsideContainer } from "components/DashboardContainer";
import styled from "styled-components";
import { useContext } from "react";
import { AccountContext } from "Account/store";

const NewUsersMessageContainer = styled.div`
  background: #f3f3f3;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 50px;
`;

const NewUsersMessage = () => {
  const [state] = useContext(AccountContext);

  if (
    state.profile &&
    !state.profile.isSupporter &&
    !state.profile.isVolunteer &&
    !state.profile.wasGrantedTicket
  ) {
    return (
      <NewUsersMessageContainer>
        <DashboardInsideContainer>
          <div className="text-weight-600 mb-1">
            Welcome to WWDC Community Week!
          </div>
          <p className="mb-0">
            You can now start RSVP'ing to events. However please take note that
            doing so doesn’t guarantee you access to the event. We are raffling
            priority slots tickets to those who tweeted that they'll be
            attending the event and tag{" "}
            <a
              className="text-pink text-weight-500"
              href="https://twitter.com/wwdccommunity"
              target="_blank"
              rel="noopener noreferrer"
            >
              @WWDCCommunity
            </a>
            .
          </p>
        </DashboardInsideContainer>
      </NewUsersMessageContainer>
    );
  }

  return null;
};

export default NewUsersMessage;
