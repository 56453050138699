const globalConstants = {
  dateFormats: {
    firebase: "M/D/YYYY hh:mm A",
    monthWithDay: "MMM DD",
    day: "DD",
    time: "hh:mm A",
    monthWithDayAndTime: "MMM DD, hh:mm A",
  },
};

export default globalConstants;
