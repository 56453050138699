const AttendeesIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <rect width="20" height="20" fill="white" />
      <path
        d="M17.1131 10.6768H15.5664C15.7241 11.1084 15.8102 11.5742 15.8102 12.0597V17.9054C15.8102 18.1079 15.775 18.3022 15.7109 18.4829H18.2679C19.2231 18.4829 20.0002 17.7058 20.0002 16.7506V13.5638C20.0002 11.9719 18.7051 10.6768 17.1131 10.6768Z"
        fill="#677E9A"
      />
      <path
        d="M4.19005 12.0597C4.19005 11.5742 4.27618 11.1084 4.43384 10.6768H2.88712C1.29516 10.6768 0 11.9719 0 13.5639V16.7506C0 17.7058 0.777072 18.4829 1.73227 18.4829H4.28938C4.22528 18.3021 4.19005 18.1079 4.19005 17.9054V12.0597V12.0597Z"
        fill="#677E9A"
      />
      <path
        d="M11.7688 9.17236H8.23282C6.64086 9.17236 5.3457 10.4675 5.3457 12.0595V17.9052C5.3457 18.2241 5.60422 18.4826 5.92313 18.4826H14.0785C14.3974 18.4826 14.656 18.2241 14.656 17.9052V12.0595C14.656 10.4675 13.3608 9.17236 11.7688 9.17236Z"
        fill="#677E9A"
      />
      <path
        d="M9.99946 1.51709C8.08492 1.51709 6.52734 3.07467 6.52734 4.98925C6.52734 6.28788 7.2441 7.42206 8.30262 8.01753C8.80469 8.29996 9.38352 8.46136 9.99946 8.46136C10.6154 8.46136 11.1942 8.29996 11.6963 8.01753C12.7549 7.42206 13.4716 6.28784 13.4716 4.98925C13.4716 3.07471 11.914 1.51709 9.99946 1.51709Z"
        fill="#677E9A"
      />
      <path
        d="M3.90332 4.75342C2.47149 4.75342 1.30664 5.91826 1.30664 7.3501C1.30664 8.78194 2.47149 9.94679 3.90332 9.94679C4.26653 9.94679 4.61239 9.87155 4.92657 9.73632C5.46977 9.50245 5.91766 9.08846 6.19481 8.56991C6.38935 8.20596 6.50001 7.79077 6.50001 7.3501C6.50001 5.9183 5.33516 4.75342 3.90332 4.75342Z"
        fill="#677E9A"
      />
      <path
        d="M16.0967 4.75342C14.6648 4.75342 13.5 5.91826 13.5 7.3501C13.5 7.79081 13.6107 8.206 13.8052 8.56991C14.0823 9.0885 14.5302 9.50249 15.0734 9.73632C15.3876 9.87155 15.7335 9.94679 16.0967 9.94679C17.5285 9.94679 18.6934 8.78194 18.6934 7.3501C18.6934 5.91826 17.5285 4.75342 16.0967 4.75342Z"
        fill="#677E9A"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AttendeesIcon;
