export const sponsors = {
  platinum: [
    {
      link: "https://www.goodnotes.com/",
      logo: 'https://res.cloudinary.com/wwdc-community/image/upload/v1622416549/sponsors/dark_background/goodnotes_logo_white_tgqubu.png',
      lightLogo: 'https://www.goodnotes.com/images/goodnoteslogo.png',
      name: "GoodNotes",
      description:
        "Take beautiful, searchable handwritten notes with the note-taking app GoodNotes that turns your iPad into digital paper.",
    },
  ],
};
