import React from 'react'
import DashboardContainer, {
    DashboardInsideContainer,
  } from "components/DashboardContainer";
import styled from "styled-components";
import Banner from "../components/images/goodnotes-banner.png"
import Logo from "../components/images/goodnotes-logo.png"

const GoodNotesBanner = styled.img`
    width: calc(200px + 20vw);
`;

const GoodNotesPageLogo = styled.img`
    width: calc(150px + 10vw);
`;

const GoodNotesCenterFlex = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '100px'
}

const GoodNotesTagline = styled.p`
    margin-top: 20px;
    max-width: 600px;
    text-align: center;
    color: #2C2929;
    font-size: calc(12px + 0.4vw);
`;

const GoodPageBottomButtonFlex = styled.div`
    display: flex;
    align-items: center;
    justicy-content: center;
    margin-top: calc(10px + 0.3vw);
    @media only screen and (max-width: 400px) {
        flex-direction: column;
    }
`;

const GoodNotesPageButtons = {
    width: 'min-content',
    padding: '30px',
    textAlign: 'center'
}

const GoodNotesPageLeftButton = styled.a`
    margin-left: 20px;
    @media only screen and (max-width: 400px) {
        margin-left: 0px;
    }
`;

const HiringText = styled.p`
    color: #2C2929;
    font-size: calc(14px + 0.5vw);
    font-weight: 600;
    margin-top: calc(30px + 1vw);
    margin-bottom: 0px;
`;

const HiringUnderline = styled.div`
    background: #2BE3CD;
    margin-top: 5px;
    width: calc(60px + 6.5vw);
    height: 1px;
`;
const InvitedUnderline = styled.div`
    background: #2BE3CD;
    margin-top: 5px;
    width: calc(100px + 10vw);
    height: 1px;
`;

const GoodNotesSponsorEventFlex = styled.div`
  display: grid;
  margin-top: calc(20px + 0.6vw);
  grid-template-columns: calc(50px + 1vw) 1fr 1fr;
  align-items: center;
  justify-items: center;
  justify-content: center;
  min-height: 128px;
  @media only screen and (max-width: 400px) {
    grid-template-columns: 1fr;
  }
`;

const GoodNotesSponsorEventDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2BE3CD;
  width: calc(50px + 1vw);
  height: calc(50px + 1vw);
`;

const SmallResEventAlignment = styled.p`
    @media only screen and (max-width: 400px) {
        margin-left: 0px;
        text-align: center;
        margin-top: 10px;
        grid-template-columns: 1fr;
    }
`;

const RSVPButton = styled.a`
    @media only screen and (max-width: 400px) {
        margin-top: 25px;
    }
`;

const EventTextStyle = {
    color: '#677E9A',
    marginLeft: '20px',
    marginBottom: '0px',
    fontSize: 'calc(8px + 0.5vw)'
}

const SketchNotingStyle = { 
    fontWeight: '600',
    color: '#2C2929',
    fontSize: 'calc(11px + 0.6vw)',
    marginLeft: '20px',
    marginBottom: '0px' 
}

const EventTimeStyle = { 
    fontWeight: '600',
    color: '#2C2929',
    fontSize: 'calc(8px + 0.5vw)',
    marginLeft: '20px',
    marginBottom: '0px' 
}

const GoodNotesApply = styled.a`
`;

const GoodNotesApplyButton = styled.button`
    color: #1756FA;
    transition: .2s all;
    width: 90px;
    height: 35px;
    border-radius: 4px;
    background: none;
    border: none;
    cursor: pointer;
    :hover {
        background: rgb(23, 86, 250, 0.2)
    }
`;


const GoodNotes = () => {
    return(
        <DashboardContainer>
            <DashboardInsideContainer className="mt-5" style={GoodNotesCenterFlex}>
                <GoodNotesBanner src={Banner} />
                <a target="_blank" rel="noreferrer" href="https://www.goodnotes.com">
                    <GoodNotesPageLogo src={Logo} />
                </a>
                <GoodNotesTagline>
                    Take beautiful, searchable handwritten notes with the note-taking app GoodNotes that turns your iPad into digital paper.
                </GoodNotesTagline>
                <GoodPageBottomButtonFlex>
                    <a style={GoodNotesPageButtons} target="_blank" rel="noreferrer" href="https://www.goodnotes.com" className="btn btn-primary py-2 text-weight-500 mb-2">Visit Website</a>
                    <GoodNotesPageLeftButton style={GoodNotesPageButtons} href="/events/sketchnoting-with-goodnotes" className="btn btn-primary py-2 text-weight-500 mb-2">Learn Sketchnoting</GoodNotesPageLeftButton>
                </GoodPageBottomButtonFlex>
                <HiringText>We are hiring</HiringText>
                <HiringUnderline></HiringUnderline>
                <GoodNotesTagline style={{ maxWidth: '90%'}}>
                    We are looking for multiple senior iOS mobile application engineers to join us to plan and drive our new initiatives. Our iOS app has been featured by Apple and loved by millions. You will build and own the entire lifecycle of new user-facing features, often leveraging technologies unique to the latest frameworks. This is a not-to-be-missed opportunity to work with some of the best iOS engineers in the region.
                </GoodNotesTagline>
                <GoodNotesApply target="_blank" rel="noreferrer" href="https://jobs.lever.co/goodnotes/58bb52f8-5a97-40a0-a808-2e84cf73a9e6?lever-origin=applied&lever-source%5B%5D=WWDCCommunity2021">
                    <GoodNotesApplyButton>APPLY</GoodNotesApplyButton>
                </GoodNotesApply>
                <HiringText>You are invited to Join</HiringText>
                <InvitedUnderline></InvitedUnderline>
                <GoodNotesSponsorEventFlex>
                    <GoodNotesSponsorEventDate>
                      <p style={
                        { textAlign: 'center', margin: '0px', fontWeight: '600', fontSize: 'calc(12px + 0.4vw)'}
                      }>Jun <br /> 6</p>
                    </GoodNotesSponsorEventDate>
                    <div>
                      <SmallResEventAlignment style={EventTextStyle}>Sponsor's Event</SmallResEventAlignment>
                      <SmallResEventAlignment style={SketchNotingStyle}>Sketchnoting with GoodNotes</SmallResEventAlignment>
                      <SmallResEventAlignment style={EventTimeStyle}>02:00 PM - 03:00 PM (UTC)</SmallResEventAlignment>
                    </div>
                    <div>
                      <RSVPButton href="/events/sketchnoting-with-goodnotes" className="btn btn-primary px-5 py-2 text-weight-500 mb-2 ml-4">
                        RSVP
                      </RSVPButton>
                    </div>
                  </GoodNotesSponsorEventFlex>
            </DashboardInsideContainer>
        </DashboardContainer>
    )
}

export default GoodNotes