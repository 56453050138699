import styled from 'styled-components';

export const UploadProfileImageContainer = styled.div`
    cursor:pointer;

    .profile-picture {
        padding:40px;
    }
    .profile-picture .image-drop {
        height:100px;
        width:100px;
        border: 3px dashed #F3F3F3;
        border-radius:50px;
        display:inline-block;
    }
    .profile-picture .image-drop img {
        width:100%;
        height:100%;
        border-radius:50px;
        z-index:-1;
    
    }
    .profile-picture .image-drop p {
        text-align:center;
        line-height:95px;
        font-size:14px;
        margin:0px;
    }
    .profile-picture p {
        text-align:left;
        margin-left:20px;
        font-weight:500;
        font-size:14px;
        display:inline-block;
    }
    input {
        display:none;
    }
`;
