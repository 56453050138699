import React from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import { Link } from "react-router-dom";
import { AccountContext } from '../store';
import AccountContainer from "components/AccountContainer"

class LoginScreen extends React.Component {
    static contextType = AccountContext;

    constructor(props) {
        super(props);
        this.loginToAccount = this.loginToAccount.bind(this);
        this.state = { 
            message: null,
            email: "",
            password: "",
            loading: false
        };
    }

    loginToAccount(event) {
        event.preventDefault();
        this.setState({ loading: true });

        firebase
            .auth()
            .signInWithEmailAndPassword(this.state.email, this.state.password)
            .then(() => {
                this.setState({ loading: false });
            })
            .catch((error) => {
                // Display Error Message to User:
                this.setState({
                    message: error.message,
                    loading: false
                });
            });
    }

    handleInputChange(name, value) {
        this.setState({ [name]: value });
    }

    render() {
        return (<AccountContainer>
            <h1>Log in</h1>
            <p>{this.state.message}</p>
            <form onSubmit={this.loginToAccount}>
                <input 
                    name="email" 
                    type="email"
                    placeholder="Email Address"
                    className="form-control mb-4"
                    value={this.state.email}
                    onChange={(event) =>
                        this.handleInputChange("email", event.target.value)
                    }
                    autoFocus
                />
                <input 
                    name="password"
                    type="password" 
                    placeholder="Password"
                    className="form-control mb-4"
                    value={this.state.password}
                    onChange={(event) =>
                        this.handleInputChange("password", event.target.value)
                    }
                />
                <button 
                    type="submit" 
                    className="btn btn-primary"
                    disabled={
                        this.state.email === "" ||
                        this.state.password === "" ||
                        this.state.loading
                    }
                >
                    Sign in
                </button>
            </form>
            <p className="mb-5">
                <Link to="/forgot-password" className="is-text-cobble" style={{fontSize: "12px"}}>
                    Forgot your password?
                </Link>
            </p>

            <p className="text-sm text-cobble mb-0">Support the Event</p>
            <a
                href="https://ko-fi.com/s/4d8fb2181a"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm"
            >
                Get your ticket here
            </a>
        </AccountContainer>)
    }
}

export default LoginScreen;
