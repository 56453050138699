import { DashboardInsideContainer } from "components/DashboardContainer";
import styled from "styled-components";
import { useContext } from "react";
import { AccountContext } from "Account/store";

const MentoringLabsMessageContainer = styled.div`
  background: #f3f3f3;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 50px;
`;

const MentoringLabsMessage = () => {
  const [state] = useContext(AccountContext);

  if (state.profile && !state.profile.isSupporter) {
    return (
      <MentoringLabsMessageContainer>
        <DashboardInsideContainer>
          <div className="text-weight-600 mb-1">
            Welcome to WWDC Community Week!
          </div>
          <p className="mb-0">
            For the mentorship labs, choose <strong>only one</strong> mentoring
            session for the week so that others can have an opportunity too.
            Starting June 6 - 12:00 AM PST time, you can now book a schedule
            with a mentor and make sure to attend as a courtesy to the mentor.
            This is on a first come, first serve basis. Please follow the{" "}
            <a
              className="text-pink text-weight-500"
              href="https://bit.ly/wwdc-coc"
              target="_blank"
              rel="noopener noreferrer"
            >
              Code of Conduct
            </a>{" "}
            and read the{" "}
            <a
              className="text-pink text-weight-500"
              href="[https://bit.ly/wwdc-mentorsession-disclaimer"
              target="_blank"
              rel="noopener noreferrer"
            >
              disclaimer
            </a>{" "}
            before participating in the session.
          </p>
        </DashboardInsideContainer>
      </MentoringLabsMessageContainer>
    );
  }

  return null;
};

export default MentoringLabsMessage;
