const HouseIcon = () => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0003 4.30566L3.4408 11.3621C3.4408 11.372 3.4383 11.3867 3.43329 11.4066C3.42839 11.4265 3.42578 11.4408 3.42578 11.4511V18.5964C3.42578 18.8544 3.5201 19.078 3.70869 19.2663C3.89723 19.4547 4.1205 19.5494 4.37854 19.5494H10.0947V13.833H13.9059V19.5497H19.622C19.88 19.5497 20.1036 19.4551 20.2919 19.2663C20.4805 19.0782 20.5751 18.8545 20.5751 18.5964V11.4511C20.5751 11.4114 20.5697 11.3815 20.5601 11.3621L12.0003 4.30566Z"
      fill="white"
    />
    <path
      d="M23.8344 9.75432L20.5745 7.045V0.971337C20.5745 0.832488 20.5299 0.718301 20.4403 0.628932C20.3515 0.539668 20.2373 0.495036 20.0981 0.495036H17.24C17.101 0.495036 16.9869 0.539668 16.8974 0.628932C16.8082 0.718301 16.7637 0.83254 16.7637 0.971337V3.87414L13.1315 0.837233C12.8143 0.57919 12.4371 0.450195 12.0003 0.450195C11.5637 0.450195 11.1865 0.57919 10.869 0.837233L0.165418 9.75432C0.0661949 9.83357 0.0118127 9.94025 0.00169755 10.0743C-0.0083655 10.2081 0.0263077 10.3251 0.105821 10.4243L1.02875 11.5259C1.10827 11.6152 1.21234 11.6697 1.34139 11.6897C1.46053 11.6997 1.57967 11.6649 1.69881 11.5855L12 2.99584L22.3014 11.5855C22.3809 11.6547 22.4849 11.6893 22.614 11.6893H22.6587C22.7876 11.6697 22.8914 11.6147 22.9714 11.5257L23.8944 10.4242C23.9738 10.3248 24.0085 10.2081 23.9982 10.074C23.988 9.94041 23.9334 9.83373 23.8344 9.75432Z"
      fill="white"
    />
  </svg>
);

export default HouseIcon;
