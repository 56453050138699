import { useEffect } from "react";
import Modal from "react-modal";
import styled from "styled-components";

const MentoringModalStyled = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 350px;
  max-height: 400px;
  margin: 0 auto;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  position: absolute;
  box-shadow: 0px 4px 17px rgba(103, 94, 94, 0.125);
  padding: 30px;
  background-color: white;
  border-radius: 10px;
  &:focus {
    outline: none;
  }
`;

function MentoringLabsDialog({ isOpen, onRequestClose, mentorLink }) {
  useEffect(() => {
    Modal.setAppElement("body");
  });

  return (
    <MentoringModalStyled
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
    >
      <h1 className="text-pink text-weight-600 text-xxl">Before you book</h1>
      <p className="my-2">
        Choose <strong>only one</strong> mentoring session for the week so that
        others can have an opportunity too!
      </p>
      <a
        className="btn btn-primary text-weight-500 text-lg text-white mt-2"
        href={mentorLink}
      >
        Book a slot
      </a>
    </MentoringModalStyled>
  );
}

export default MentoringLabsDialog;
