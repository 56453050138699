import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { useParams } from "react-router-dom";
import DashboardContainer, { DashboardInsideContainer } from "components/DashboardContainer";
import DashboardLoading from "components/DashboardLoading";
import { UserPreview } from "components/UserView/UserPreview";
import firebase from "firebase/app";

const HackathonHeader = styled.div`
    box-shadow: 0px 5px 5px rgba(103, 94, 94, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    background-color: white;
    z-index: 1;
`;

const HackathonHeaderFlex = styled.div`
    margin-top: calc(10px + 1vw);
    margin-bottom: calc(10px + 1vw);
    display: flex;
    width: 91%;
`;

const HackathonProjectLogo = styled.img`
    width: calc(50px + 2.5vw);
    border-radius: calc(10px + 0.5vw);
`;

const ProjectDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: calc(10px + 1vw);
`;

const HackathonTag = styled.p`
    font-size: calc(10px + 0.35vw);
    font-weight: 500;
    color: #677E9A;
    margin-bottom: 0px;
`;

const ProjectName = styled.p`
    font-size: calc(12px + 0.6vw);
    font-weight: 600;
    color: #2C2929;
    margin-bottom: 0px;
`;

const TeamName = styled.p`
    font-size: calc(10px + 0.5vw);
    font-weight: 600;
    color: #2C2929;
    margin-bottom: 0px;
`;

const HeaderBorder = styled.div`
    width: 91%;
    height: 1px;
    margin-bottom: calc(10px + 1vw);
    background: #F3F3F3;
`;

const ViewCode = styled.a`
    width: calc(50px + 5vw);
    height: calc(30px + 1vw);
    font-size: calc(10px + 0.5vw);
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const HeaderTabFlex = styled.div`
    display: flex;
    width: 91%;
`;

const Tabs = styled.p`
    font-size: calc(10px + 0.5vw);
    font-weight: 500;
    color: #2C2929;
    cursor: pointer;
    transition: .3s all;
    margin-bottom: 0px;
    :hover {
        color: pink;
    }
`

const TabOneLine = styled.div`
    width: 100%;
    height: 1.5px;
    background: #2BE3CD;
    margin-bottom: calc(10px + 1vw);
`
const TabTwoLine = styled.div`
    width: 70%;
    height: 1.5px;
    background: #2BE3CD;
    margin-bottom: calc(10px + 1vw);
    margin-left: calc(10px + 1vw);
`

const ContentTag = styled.p`
    font-size: calc(12px + 0.6vw);
    color: #2C2929;
    font-weight: 600;
    margin-top: calc(20px + 2vw);
`
const ContentLines = styled.p`
    font-size: calc(10px + 0.5vw);
    color: #2C2929;
    font-weight: 500;
    margin-top: calc(10px + 1vw);
`

const Embed = styled.div`
    width: calc(170.6px + 20vw);
    height: calc(96px + 10vw);
`;

const TeamImage = styled.img`
    width: calc(200px + 20vw);
`;

const TeamGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
    grid-row-gap: 40px;
    width: 80%;
    margin-top: calc(10px + 1vw);

    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        width: 100%;
        grid-column-gap: 30px;
        grid-row-gap: 30px;
    }
`;

const HackathonScreen = () => {
    let { teamName } = useParams();
    const [loading, setLoading] = useState(true)
    const [project, setProject] = useState()
    const [tab, setTab] = useState('Details')
    const [members, setMembers] = useState([])

    useEffect(() => {
        fetch("https://api.airtable.com/v0/appZGpRTkEij893tS/Hackathon?maxRecords=100&view=Grid+view", {
            "headers": {
                "authorization": "Bearer keyNfd02JL9fMEUlx",
            },
            "method": "GET",
            "mode": "cors"
        })
        .then(res => res.json())
        .then(json => {
            const data = json.records.map((element) => element.fields)
            data.forEach((record) => {
                if (!record.team_name) return
                if (record.team_name.toLowerCase().replace(' ', '-') === teamName) {
                    setProject(record)
                    const localMembers = []
                    record["user_id (from Hackathon Team Members)"].forEach((member) => {
                        firebase
                            .database()
                            .ref('users/' + member)
                            .get()
                            .then((snapshot) => {
                                if (snapshot.exists()) {
                                    localMembers.push(snapshot.val())
                                }
                            })
                    })
                    setMembers(localMembers)
                    setLoading(false)
                }
            })
        })
        .catch((err) => {
            console.error(err)
            setLoading(false)
        })
    }, [teamName])

    return (
        <DashboardContainer>
            
            {loading && (
                <DashboardLoading />
            )}

            {!loading && project && (
                <>
                    <HackathonHeader>
                        <HackathonHeaderFlex>
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <HackathonProjectLogo src={project.project_logo_url} alt="Logo" />
                            </div>
                            <div style={{ display: 'flex', width: '91%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <ProjectDetails>
                                    <HackathonTag>HACKATHON</HackathonTag>
                                    <ProjectName>{project.project_name}</ProjectName>
                                    <TeamName>{project.team_name}</TeamName>
                                </ProjectDetails>
                                <ViewCode href={project.github_url} target="_blank" rel="noopener noreferrer" className={`btn btn-primary px-5 py-2 text-weight-500 ${project.github_url ? '' : 'disabled'}`}>View Code</ViewCode>
                            </div>
                        </HackathonHeaderFlex>
                        <HeaderBorder />

                        <HeaderTabFlex>
                            <div>
                                <Tabs onClick={() => setTab('Details')}>Details</Tabs>
                                {tab === 'Details' && (
                                    <TabOneLine></TabOneLine>
                                )}
                            </div>
                            <div>
                                <Tabs onClick={() => setTab('Team')} style={{ marginLeft: 'calc(10px + 1vw)'}}>Team</Tabs>
                                {tab === 'Team' && (
                                    <TabTwoLine></TabTwoLine>
                                )}
                            </div>
                        </HeaderTabFlex>
                    </HackathonHeader>

                    <DashboardInsideContainer>
                        {tab === 'Details' && (
                            <>
                                <ContentTag>Description</ContentTag>
                                <ContentLines>{project.description}</ContentLines>
                                <Embed>
                                    <iframe width="100%" height="100%" src={project.youtube_url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen></iframe>
                                </Embed>
                                <ContentTag>Technology Used</ContentTag>
                                <ContentLines>{project.technology_used}</ContentLines>
                                <ContentTag>Challenges Faced</ContentTag>
                                <ContentLines>{project.challenges}</ContentLines>
                            </>
                        )}
                        {tab === 'Team' && (
                            <>
                                <ContentTag>{project.team_name}</ContentTag>
                                <TeamImage alt="Team Photo" src={project.team_photo_url}></TeamImage>
                                <ContentTag>Members</ContentTag>
                                <TeamGrid>
                                    {Object.keys(members).map((key, index) => (
                                        <UserPreview
                                            key={key}
                                            user={members[key]}
                                            index={index}
                                            userId={project["user_id (from Hackathon Team Members)"][index]}
                                        />
                                    ))}
                                </TeamGrid>
                                <ContentTag>Team Fun Fact</ContentTag>
                                <ContentLines>{project.team_fun_fact}</ContentLines>
                            </>
                        )}
                    </DashboardInsideContainer>
                </>
            )}
        </DashboardContainer>
    )
}

export default HackathonScreen