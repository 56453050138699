import React from "react";
import {
  SplashScreenContainer,
  SplashHeader,
  SplashHeading,
  Sponsors,
  LastYear,
  SplashButton,
} from "./styles";
// import { SplashScreenContainer, SplashHeader, SplashHeading, SponsorHeading, SponsorLogo, SupportColumn, Sponsors, LastYear, SplashButton, CommunityHeroes, Attendees, Team, EventVolunteers } from './styles';
import { Link } from "react-router-dom";
import SponsorFetch from "components/SponsorFetch";
import logo from "./../logo.png";
// import support from "./icons/support.svg";
// import volunteer from "./icons/volunteer.svg";
// import sponsor from "./icons/sponsor.svg";
import keynote from "./icons/keynote.svg";
import openmic from "./icons/openmic.svg";
import group from "./icons/group.svg";
import mentorship from "./icons/mentorship.svg";
import hackathon from "./icons/hackathon.svg";
import { AccountContext } from "Account/store";
import SplashScreenCommunityPartners from "components/SplashScreenCommunityPartners";

class SplashScreen extends React.Component {
  static contextType = AccountContext;

  render() {
    var [state] = this.context;

    return (
      <SplashScreenContainer>
        <SplashHeader>
          {/* <div className="container cta-links"> */}
          {/*   {!state.user && ( */}
          {/*     <p> */}
          {/*       <Link to="/join">Register</Link> or{" "} */}
          {/*       <Link to="/login">Login</Link> */}
          {/*     </p> */}
          {/*   )} */}
          {/* </div> */}
          <div className="container content">
            <img src={logo} width="100%" className="App-logo" alt="logo" />
            <h2>June 5-13, 2021</h2>

            <Link to="/events">
              <SplashButton className="btn btn-primary pink">
                {/* {state.user ? "Enter dashboard" : "Register"} */}
                View Pasts Events
              </SplashButton>
            </Link>
          </div>
        </SplashHeader>
        <div className="container">
          <div className="row no-gutters">
            <div>
              <h4>
                Connect, share, and collaborate with others for an entire week
              </h4>
              <p>
                Once again let us enjoy each others company as we explore and
                discover new exciting things from{" "}
                <a
                  className="red-link"
                  href="https://developer.apple.com/wwdc21/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Apple’s WWDC 2021
                </a>{" "}
                week long event. This year we are going to have more fun and
                interactive activities and we can’t wait to share them all to
                you!
                <br />
                <br />
                Check out what we did last year.
                <br />
                <LastYear href="https://wwdcwatch.party" target="_blank">
                  WWDC Watch Party
                </LastYear>
              </p>
            </div>
          </div>

          <div className="row text-center">
            <SplashHeading>Events and Activities</SplashHeading>
          </div>
          <div className="row event">
            <div className="col-lg-1 col-md-2 col-sm-3">
              <img src={keynote} alt="A TV with a play button, keynote icon" />
            </div>
            <div className="col-lg-11 col-md-10 col-sm-9">
              <h4>Keynote Watch Party</h4>
              <p>
                Time to get the popcorn ready and let's watch the Keynote and
                SOTU together as a community.
              </p>
              <p>JUNE 07, 2021</p>
            </div>
          </div>
          <div className="row event">
            <div className="col-lg-1 col-md-2 col-sm-3">
              <img src={openmic} alt="A microphone icon" />
            </div>
            <div className="col-lg-11 col-md-10 col-sm-9">
              <h4>Open Mic Night</h4>
              <p>
                Launching an app this WWDC21 Week, pitch it to the community!
              </p>
              <p>JUNE 08, 2021</p>
            </div>
          </div>
          <div className="row event">
            <div className="col-lg-1 col-md-2 col-sm-3">
              <img
                src={group}
                alt="Four people surrounding a speech bubble, icon"
              />
            </div>
            <div className="col-lg-11 col-md-10 col-sm-9">
              <h4>Group Get Togethers</h4>
              <p>
                Meet your fellow developers. Get Togethers Groups are Beginners,
                Intermediate/Advance, Students, SwiftUI, A11y, Freelancers,
                Indie Devs, Open Source, iOS Security, Community Leaders,
                Mentors, Women, LGBTQ, Black, Latinx, Asian.
              </p>
              <p>JUNE 05-13, 2021</p>
            </div>
          </div>
          <div className="row event">
            <div className="col-lg-1 col-md-2 col-sm-3">
              <img
                src={mentorship}
                alt="A hand holding a lightbulb emitting beam of light, icon"
              />
            </div>
            <div className="col-lg-11 col-md-10 col-sm-9">
              <h4>Career, Help, Mentorship Labs</h4>
              <p>
                Need help with your career or have those burning questions in
                your code or developer journey. Join the labs for a one-on-one
                session with a mentor.
              </p>
              <p>JUNE 08-13, 2021</p>
            </div>
          </div>
          <div className="row event">
            <div className="col-lg-1 col-md-2 col-sm-3">
              <img
                src={hackathon}
                alt="A laptop displaying binary digits next to a stopwatch, icon"
              />
            </div>
            <div className="col-lg-11 col-md-10 col-sm-9">
              <h4>Community Hackathon</h4>
              <p>
                A 24 hour hackathon on the new technologies announced in WWDC21.
                There should at least be 2 members in your team and the winner
                will be voted by the community.
              </p>
              <p>JUNE 12-13, 2021</p>
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <Link to="/events">
                <SplashButton className="btn btn-primary pink">
                  Join events
                </SplashButton>
              </Link>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="text-center">
              <SplashHeading>Community Partners</SplashHeading>
              <div className="community-heroes mt-4">
                <SplashScreenCommunityPartners />
              </div> 
              <a href="/community-partners" className="btn btn-primary pink text-xl mt-5 text-weight-500 px-5 py-2">
                Know more
              </a>
            </div>
          </div>
          <div className="row text-center">
            <SplashHeading>Sponsors</SplashHeading>
          </div>
          <Sponsors>
            <p className="text-center">
              Thank you to all our sponsors in supporting us make this event
              happen.
            </p>
            <SponsorFetch />
          </Sponsors>
          {/*<!--div className="row text-center">
                        <SplashHeading>Community Heroes</SplashHeading>
                    </div>
                    <CommunityHeroes>
                        <p>Thank you to all these generous people who helped support other attendees.</p>
                    </CommunityHeroes>
                    <div className="row text-center">
                        <SplashHeading>Attendees</SplashHeading>
                    </div>
                    <Attendees>
                        <button>See all attendees</button>
                    </Attendees>
                    <div className="row text-center">
                        <SplashHeading>Team</SplashHeading>
                        </div>
                    <Team>
                        <p>Thank you to all these people who volunteered their time, effort, and skills in making this event happen.</p>
                    </Team>
                    <div className="row text-center">
                        <SplashHeading>Event Volunteers</SplashHeading>
                    </div>
                    <EventVolunteers>
                        <button>See profiles and roles</button>
                        <p>Thank you to all these generous people who helped support other attendees.</p>
                    </EventVolunteers>*/}
        </div>
        <footer>
          <div className="container">
            <div className="d-flex flex-column flex-md-row justify-content-between text-center text-md-left">
              Copyright 2021
              <div className="footer-links d-flex flex-column flex-md-row">
                <div>
                  <a
                    href="https://docs.google.com/document/d/10M9OqeiFduAXRP_nl0EGPPjR-iAn8DQef_kEXWZQNj8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms &amp; Conditions
                  </a>
                </div>
                <div>
                  <a
                    href="https://docs.google.com/document/d/1dLCUHP7p_Gp1tlUP7aBKcWw3ZbaVy1GmkCuG2xE8dIU"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </div>
                <div>
                  <a
                    href="https://docs.google.com/document/d/1ljIzgemDwZjRjfA71uJAZPcmClt0F4vYk2ExT25OvCw"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Code of Conduct
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="container disclaimers">
          <p>
            DISCLAIMER
            <br />
            <br />
            WWDC Community Week (wwdc.community) is a community run event made
            with ❤️ by volunteers around the world. It is not affiliated with or
            endorsed by Apple, Inc. or Apple's Worldwide Developer Conference.
            The Apple Logo are trademarks of Apple Inc., registered in the U.S.
            and other countries.
          </p>
        </div>
      </SplashScreenContainer>
    );
  }
}

export default SplashScreen;
