import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";

function AddToCalendarButton({ event, startDate, endDate }) {
  const eventTimeBeforeText =
    event.id === "keynote-sotu-watch-party"
      ? "3 hours"
      : "1 hour and 30 minutes";
  let eventData = {
    name: event.name,
    details: `${event.description} The venue will open approximately ${eventTimeBeforeText} before event proper. Please visit https://wwdc.community/events/${event.id} for the venue link. Any questions or concerns? Feel free to tweet us @WWDCCommunity!`,
    location: `https://wwdc.community/events/${event.id}`,
    startsAt: startDate.toISOString(),
    endsAt: endDate.toISOString(),
  };

  return <AddToCalendar event={eventData} filename={event.id} />;
}

export default AddToCalendarButton;
