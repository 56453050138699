import React from 'react';
import { UploadProfileImageContainer } from './styles'

class UploadProfileImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { imageFile: null, imageSrc: null };


        this.fileReader = new FileReader();
        this.fileReader.onload = e => {
            this.setState({ imageSrc: e.target.result });
            this.props.onChange(this.state.imageFile);
        }
    }

    chooseFile() {
        document.getElementById('file-input').click();
    }

    didSelectFile(file) {
        this.setState({ imageFile: file })
        this.fileReader.readAsDataURL(file);
    }

    preventDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    onDrop(event) {
        this.preventDefault(event);
        this.didSelectFile(event.dataTransfer.files[0]);
    }

    render() {
        var imageDrop;

        if (this.state.imageSrc) {
            imageDrop = (<img aria-hidden alt="Your Profile Picture" src={this.state.imageSrc} />);
        } else {
            imageDrop = (<p className="text-cobble">Drop Here</p>);
        }


        return (
            <UploadProfileImageContainer
                onClick={() => this.chooseFile() }
                onDragEnter={(event) => this.preventDefault(event) }
                onDragOver={(event) => this.preventDefault(event) }
                onDrop={(event) => this.onDrop(event)}>
                <div className="row profile-picture">
                    <div style={{margin: 'auto'}}>
                        <div className="image-drop">
                            { imageDrop }
                        </div>
                        <p className="text-link">Upload<br/>profile photo</p>
                    </div>
                    <input
                        type="file"
                        accept=".jpg,.png,.HEIC" 
                        id="file-input" 
                        onChange={(event) => this.didSelectFile(event.target.files[0]) }/>
                </div>
            </UploadProfileImageContainer>
        );
    }
    
}

export default UploadProfileImage;
