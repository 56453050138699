import { useState, useEffect } from "react";
import firebase from "firebase/app";
import DashboardContainer, {
  DashboardInsideContainer,
} from "components/DashboardContainer";
import MentoringLabsMessage from "components/MentoringLabsMessage";
import styled from "styled-components";
import {
  MentoringLabsLoadingPreview,
  MentoringLabsPreview,
} from "components/MentoringLabsPreview";
import MentoringLabsDialog from "components/MentoringLabsDialog";
import dayjs from "dayjs";

const MentoringLabsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 40px;
  width: 70%;
  max-width: 1000px;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const MentoringLabsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [mentoringLabs, setMentoringLabs] = useState([]);
  const [ongoingMentoringLabKeys, setOngoingMentoringLabKeys] = useState([]);
  const [doneMentoringLabKeys, setDoneMentoringLabKeys] = useState([]);
  const [currentMentorDialog, setCurrentMentorDialog] = useState({
    isOpen: false,
    mentorLink: null,
  });

  useEffect(() => {
    setLoading(true);

    firebase
      .database()
      .ref("mentoringLabs")
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          const mentoringLabs = snapshot.val();
          let shuffledKeys = Object.keys(mentoringLabs)
            .map((a) => ({ sort: Math.random(), value: a }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);

          let doneMentoringLabKeys = shuffledKeys.filter((eventKey) => {
            const event = mentoringLabs[eventKey];
            const isEventDone = dayjs(event.end_date).isBefore(dayjs());
            return isEventDone;
          });

          let ongoingMentoringLabKeys = shuffledKeys.filter(
            (eventKey) => !doneMentoringLabKeys.includes(eventKey)
          );

          setDoneMentoringLabKeys(doneMentoringLabKeys);
          setOngoingMentoringLabKeys(ongoingMentoringLabKeys);
          setMentoringLabs(snapshot.val());
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, []);

  return (
    <DashboardContainer>
      <MentoringLabsMessage />
      <DashboardInsideContainer className="mt-5">
        <h1 className="text-xxxl text-weight-600 mb-4">Mentoring Labs</h1>
        <MentoringLabsGrid>
          {loading &&
            [1, 2, 3, 4, 5, 6].map((index) => (
              <MentoringLabsLoadingPreview key={index} />
            ))}

          {!loading &&
            ongoingMentoringLabKeys.map((key, index) => (
              <MentoringLabsPreview
                slug={key}
                mentoringLab={mentoringLabs[key]}
                index={index}
                key={index}
                onClick={() =>
                  setCurrentMentorDialog({
                    isOpen: true,
                    mentorLink: mentoringLabs[key].calendlyLink,
                  })
                }
              />
            ))}
        </MentoringLabsGrid>
        <h1 className="text-xxxl text-weight-600 mt-5 mb-4">
          Past Mentoring Labs
        </h1>
        <MentoringLabsGrid>
          {!loading &&
            doneMentoringLabKeys.map((key, index) => (
              <MentoringLabsPreview
                slug={key}
                mentoringLab={mentoringLabs[key]}
                index={index}
                key={index}
                onClick={() =>
                  setCurrentMentorDialog({
                    isOpen: true,
                    mentorLink: mentoringLabs[key].calendlyLink,
                  })
                }
              />
            ))}
        </MentoringLabsGrid>
        <MentoringLabsDialog
          isOpen={currentMentorDialog.isOpen}
          mentorLink={currentMentorDialog.mentorLink}
          onRequestClose={() =>
            setCurrentMentorDialog({ isOpen: false, mentorLink: null })
          }
        />
      </DashboardInsideContainer>
    </DashboardContainer>
  );
};

export default MentoringLabsScreen;
