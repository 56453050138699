import firebase from "firebase/app";

export const getNestedData = (dbRef, childTable) => {
  return dbRef.once("value").then((snapshot) => {
    if (snapshot.exists()) {
      const promises = [];
      const childIds = Object.keys(snapshot.val());

      for (const childId of childIds) {
        promises.push(
          firebase
            .database()
            .ref(`${childTable}/${childId}`)
            .once("value")
            .then((snapshot) => {
              return { ...snapshot.val(), id: snapshot.key };
            })
            .catch((e) => {
              return null;
            })
        );
      }

      return Promise.all(promises);
    }
    return null;
  });
};
