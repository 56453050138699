import { useContext } from "react";
import { AccountContext } from "Account/store";
import styled from "styled-components";
import ProfileImageContainer from "components/ProfileImageContainer";
import dayjs from "dayjs";

const PreviewContainer = styled.a`
  box-shadow: 0px 4px 17px rgba(103, 94, 94, 0.08);
  color: #2c2929;
  transition: box-shadow 0.8s ease;

  :hover {
    color: #2c2929;
    box-shadow: 0px 10px 20px rgba(103, 94, 94, 0.2);
  }
`;

const HeaderContainer = styled.div`
  padding: 10px 10px 10px 20px;
  min-height: 140px;
  display: flex;
  flex-direction: column;
`;

const BodyContainer = styled.div`
  padding: 10px 20px;
  min-height: 210px;
`;

const EventDate = styled.div`
  background-color: white;
  min-width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  align-self: flex-end;
  text-align: center;
  margin-bottom: 16px;
`;

const EventName = styled.div`
  padding-right: 10px;
`;

const mentoringLabHeadingColors = [
  "#2BE3CD",
  "#FFEA2E",
  "#36B6FF",
  "#FF4768",
  "#FF8C38",
];

export const MentoringLabsPreview = ({
  slug,
  mentoringLab,
  index,
  onClick,
}) => {
  const [state] = useContext(AccountContext);
  const isMentoringLabDone = dayjs(mentoringLab.end_date).isBefore(dayjs());

  const mentor = mentoringLab.mentor;

  return (
    <PreviewContainer href={`/mentoring-labs/${slug}`}>
      <HeaderContainer
        style={{ background: mentoringLabHeadingColors[index % 5] }}
      >
        <EventDate className="text-lg text-weight-600">
          {mentoringLab.dates}
        </EventDate>
        <EventName className="text-xxl text-weight-600">
          {mentoringLab.name}
        </EventName>
      </HeaderContainer>
      <BodyContainer>
        <div className="d-flex align-items-center mt-2 mb-3">
          <ProfileImageContainer
            width="70px"
            height="70px"
            src={mentor.imageUrl}
            loading="lazy"
          />
          <div className="ml-3">
            <a
              href={mentor.profileLink}
              className="text-lg text-weight-500 text-black"
            >
              {mentor.firstName} {mentor.lastName}
            </a>
            <div className="text-sm text-weight-500">{mentor.profession}</div>
            {mentor.company && (
              <div className="text-sm text-weight-500 text-cobble">
                {mentor.company}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-4 text-cobble text-weight-500">
            Categories:
          </div>
          <div className="col">{mentoringLab.categories}</div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-4 text-cobble text-weight-500 pb-2">
            Language:
          </div>
          <div className="col">{mentoringLab.language}</div>
        </div>
        {!isMentoringLabDone && (
          <div className="text-right my-2">
            {state.user && (
              <button
                className="btn btn-primary text-weight-500 text-sm text-white"
                onClick={onClick}
              >
                Book a slot
              </button>
            )}
            {!state.user && (
              <a
                className="btn btn-primary text-weight-500 text-sm text-white"
                href="/join"
                target="_blank"
                rel="noopener noreferrer"
              >
                Book a slot
              </a>
            )}
          </div>
        )}
      </BodyContainer>
    </PreviewContainer>
  );
};

const LoadingContainer = styled.div`
  animation: blinker 1.5s linear infinite;
  border-radius: 6px;

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;

const LoadingContainerHeader = styled.div`
  height: 160px;
  background-color: #e8e8e8;
`;

const LoadingContainerBody = styled.div`
  height: 200px;
  background-color: #f9f9f9;
`;

export const MentoringLabsLoadingPreview = () => (
  <LoadingContainer>
    <LoadingContainerHeader />
    <LoadingContainerBody />
  </LoadingContainer>
);
