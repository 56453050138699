import styled from "styled-components";
import { useState, useEffect, useCallback, useContext } from "react";
import firebase from "firebase/app";
import { useParams } from "react-router-dom";
import DashboardContainer, {
  DashboardInsideContainer,
} from "components/DashboardContainer";
import DashboardLoading from "components/DashboardLoading";
import ProfileSocialLinks from "components/ProfileSocialLinks";
import { EventPreview } from "components/EventPreview";
import { EventsGrid } from "./EventsScreen";
import { AccountContext } from "Account/store";

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 126px 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 30px;

  img.profile-pic {
    height:126px;
    width:126px;
    border-radius:63px;
    border-width:2.5px;
    border-style:solid;
  }
`;

const AttendeeScreen = () => {
  let { id } = useParams();

  const [state] = useContext(AccountContext);
  const [loading, setLoading] = useState(false);
  const [attendee, setAttendee] = useState({});
  const [events, setEvents] = useState([]);
  const [badges, setBadges] = useState([]);

  const capitalizeWord = useCallback((word) => {
    return word
      .split('')
      .map((letter, index) =>
        index ? letter.toLowerCase() : letter.toUpperCase(),
      )
      .join('');
  }, [])

  const capitalize = useCallback((phrase) => {
    return phrase
      .split(' ')
      .map(capitalizeWord)
      .join(' ')
  }, [capitalizeWord])

  const getBadges = useCallback(() => {
    firebase
      .database()
      .ref(`badges/${id}`)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          setBadges(Object.entries(snapshot.val())
            .filter((item) => item[1])
            .map((item) => item[0])
            .map(capitalize))
        }
      })
  }, [id, capitalize])

  const getEvents = useCallback(() => {
    firebase
      .database()
      .ref('userEvents/' + id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          const events = Object
            .entries(snapshot.val())
            .filter((event) => { return event[1] })
            .map((event) => event[0])

          events.forEach((event) => loadEvent(event))
        }
      });
  }, [id])

  const getAttendee = useCallback(() => {
    setLoading(true)

    firebase
      .database()
      .ref('users/' + id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          setLoading(false);
          setAttendee(snapshot.val())
          getEvents()
          getBadges()
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      })
  }, [id, getEvents, getBadges])
  
  const loadEvent = (eventSlug) => {
    firebase
      .database()
      .ref("events/" + eventSlug)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          const newEvent = snapshot.val()
          newEvent.slug = eventSlug

          setEvents((events) => {
            let newEvents = events.concat([newEvent])
            newEvents.sort((lhs, rhs) => { 
              return new Date(lhs.start_date) - new Date(rhs.start_date)
            })
            return newEvents
          });
        }
      })
  }

  const hasBadge = (badgeName) => {
    return badges.indexOf(capitalize(badgeName)) >= 0
  }

  const toggleBadge = (button, badgeName) => {
    button.disabled = true

    const capitalized = capitalize(badgeName)
    const hasThisBadge = hasBadge(badgeName)

    firebase
      .database()
      .ref(`badges/${id}/${badgeName}`)
      .set(!hasThisBadge)
      .then(() => {
        button.disabled = false

        setBadges((badges) => {
          if (hasThisBadge) {
            return badges.filter(item => item !== capitalized)
          } else {
            return [...badges, capitalized]
          }
        })
      })
  }

  const displayBadge = (badgeName) => {
    let description = {
      'volunteer': 'Helped to run WWDC Community Week',
      'supporter': 'Supported WWDC Community Week',
      'hero': 'Supported another attendee', 
      'sponsor': ' ',
      'mentor': ' ',
      'speaker': ' ',
      'leader': ' ',
      'ticket holder': null
    }[badgeName.toLowerCase()]

    if (!description) {
      return undefined
    }

    return (
      <div key={badgeName} className="col-xs col-md-6 col-lg-4">
        <h1 className="text-lg">Community {badgeName}</h1>
        <p className="text-cobble text-weight-500">{description}</p>
      </div>
    )
  }

  useEffect(() => {
    getAttendee()
  }, [id, getAttendee]);

  if (loading) {
    return (
      <DashboardContainer>
        <DashboardLoading />
      </DashboardContainer>
    );
  }

  const imageURL = attendee.thumbnailURL ?? attendee.imageURL
  const badgeDisplay = badges.map(displayBadge).filter(item => item !== undefined)

  return (
    <DashboardContainer>
      <DashboardInsideContainer className="mt-5">
        
        <h1 className="text-xxxl text-weight-600 mb-4">{attendee.firstName}'s Profile</h1>
        <HeaderContainer>
          { imageURL && 
            <img aria-hidden 
              alt={"Profile Picture for" + attendee.firstName + ' ' + attendee.lastName } 
              className="profile-pic" src={imageURL} 
              style={{borderColor: '#2BE3CD'}} />
          }
          <div>
            <h1 className="text-xxxl text-weight-600 mb-2">{attendee.firstName} {attendee.lastName}</h1>
            <ProfileSocialLinks socials={attendee.socials} />
          </div>
        </HeaderContainer>
        <hr />
        { state.user?.isAdmin && 
          <>  
            <h1 className="text-xl text-weight-600 mb-4">Admin Actions</h1>
            { ['volunteer', 'supporter', 'hero', 'sponsor', 'mentor', 'speaker', 'leader', 'ticket holder'].map((badgeName) => {
              return <button 
                key={badgeName}
                onClick={event => toggleBadge(event.target, badgeName)} 
                type="button"
                className="btn btn-primary mr-1 mb-1">{ hasBadge(badgeName) ? "Remove" : "Make"} {capitalize(badgeName)}</button>
            })}
            <hr/>
          </>
        }
        <h1 className="text-xl text-weight-600 mb-4">Community Badges</h1>
        <div className="row">
          {
            badgeDisplay.length > 0 && badgeDisplay
          }
        </div>
        { badgeDisplay.length === 0 && 
          <p>Nothing to see here yet!</p>
        }
        <hr />
        <h1 className="text-xl text-weight-600 mb-4">Events</h1>
        <EventsGrid>
        { events.length === 0 && <p>Nothing to see here yet!</p> }
        { events.map((event, index) => (
            <EventPreview slug={event.slug} event={event} index={index} key={event.slug} />
        ))}
        </EventsGrid>
      </DashboardInsideContainer>
    </DashboardContainer>
  );
};

export default AttendeeScreen;
