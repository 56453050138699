import React from 'react';
import styled from 'styled-components';

export const EditProfileImageContainer = styled.div`
    cursor:pointer;
    .profile-picture .image-drop {
        height:164px;
        width:164px;
        border: 3px dashed #F3F3F3;
        border-radius:50%;
        display:inline-block;
    }
    .profile-picture .image-drop img {
        width:100%;
        height:100%;
        border-radius:82px;
        z-index:-1;
    
    }
    .profile-picture {
        text-align:center;
        // line-height:160px;
        font-size:14px;
        margin:0px;
    }
    .profile-picture {
        text-align:center;
        font-weight:500;
        font-size:14px;
    }
    input {
        display:none;
    }
    .text-link {
        text-align:center;
    }
    .text-cobble {
        line-height:160px;
    }
`;

class EditProfileImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = { 
            imageFile: null, 
            imageSrc: null,
        };

        this.fileReader = new FileReader();
        this.fileReader.onload = e => {
            this.setState({ imageSrc: e.target.result });
            this.props.onChange(this.state.imageFile);
        }
    }

    componentDidUpdate() {
        if (!this.state.imageSrc && this.props.imageSrc !== this.state.imageSrc) {
            this.setState({ 
                imageSrc: this.props.imageSrc
            });
        }
    }

    chooseFile() {
        document.getElementById('file-input').click();
    }

    didSelectFile(file) {
        this.setState({ imageFile: file })
        this.fileReader.readAsDataURL(file);
    }

    preventDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    onDrop(event) {
        this.preventDefault(event);
        this.didSelectFile(event.dataTransfer.files[0]);
    }

    render() {
        var imageDrop;
        if (this.state.imageSrc) {
            imageDrop = (<img aria-hidden alt="Your Profile Picture" src={this.state.imageSrc} />);
        } else {
            imageDrop = (<p className="text-cobble">Drop Here</p>);
        }


        return (
            <EditProfileImageContainer
	                onClick={() => this.chooseFile()}
	                onDragEnter={(event) => this.preventDefault(event)}
	                onDragOver={(event) => this.preventDefault(event)}
	                onDrop={(event) => this.onDrop(event)}>
	                <div className="container col profile-picture">
	                    <div className="row image-drop">
	                        {imageDrop}
	                    </div>
	                    <input 
                            className="row"
                            type="file" 
                            accept=".jpg,.png,.HEIC" 
                            id="file-input" 
                            onChange={(event) => this.didSelectFile(event.target.files[0])} />
	                    <p className="text-link">Change photo</p>
	                </div>
	            </EditProfileImageContainer>
        );
    }
    
}

export default EditProfileImage;