import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { Link } from "react-router-dom";
import AccountContainer from "components/AccountContainer";
import styled from "styled-components";

export const ForgotPasswordContainer = styled(AccountContainer)`
  button {
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 8px;
  }
`;

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const config = {
      url: "https://wwdc.community/login",
      handleCodeInApp: true,
    };

    firebase
      .auth()
      .sendPasswordResetEmail(email, config)
      .then(() => {
        setEmail("");
        setLoading(false);
        setEmailSent(true);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error.message);
        console.error(error);
      });
  };

  return (
    <ForgotPasswordContainer>
      <h1 className="mb-1">Forgot Password</h1>
      <p className="text-sm text-weight-400 mb-4">
        No need to worry. We got you covered!
      </p>
      <form onSubmit={handleSubmit}>
        <p className="text-sm text-cobble text-left">
          Enter the email address associated with your account to receive
          instructions on how to reset your password.
        </p>
        <input
          name="email"
          type="email"
          placeholder="Email Address"
          className="form-control"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus
        ></input>
        {emailSent && (
          <p className="text-sm text-success mt-1">
            Instructions sent. Please check your email
          </p>
        )}
        {message && <p className="text-sm text-danger mt-1">{message}</p>}
        <button
          type="submit"
          className="btn btn-primary mt-4"
          disabled={!email || loading}
        >
          {emailSent ? "Resend" : "Send Email"}
        </button>
      </form>
      <p className="text-sm text-cobble">
        Remembered your password? <Link to="/login">Login</Link>
      </p>
    </ForgotPasswordContainer>
  );
};

export default ForgotPasswordScreen;
