import { Component } from "react";
import firebase from "firebase/app";
import DashboardContainer, {
  DashboardInsideContainer,
} from "components/DashboardContainer";
import NewUsersMessage from "components/NewUsersMessage";
import styled from "styled-components";
import { UserPreview, UserLoadingPreview } from "components/UserView/UserPreview";
import { AccountContext } from "Account/store";

const AttendeesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 40px;
  width: 100%;
  max-width:2000px;

  @media only screen and (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 1100px) {
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

class AttendeesScreen extends Component {
  static contextType = AccountContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      searchFilter: "",
      attendees: [],
      displayCount: 24,
    };
    this.onScroll = this.onScroll.bind(this);
  }

  onScroll() {
    const maxHeight = window.innerHeight + window.scrollY
    const canScroll = this.state.displayCount < this.state.attendees.length;
    if ((maxHeight >= document.body.offsetHeight) && canScroll) {
      // At Bottom, add more content
      this.setState((state) => { return { 
        displayCount: state.displayCount * 2
      }})
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false);
    firebase
      .database()
      .ref("users")
      .orderByChild("privacy")
      .equalTo("anyone")
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.addAttendees(snapshot.val())
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(() => {
        if (!this.context[0].user) { 
          this.setState({ loading: false });
        }
        firebase
          .database()
          .ref("users")
          .orderByChild("privacy")
          .equalTo("attendees")
          .get()
          .then((snapshot) => {
            if (snapshot.exists()) {
              this.addAttendees(snapshot.val())
              this.setState({ loading: false })
            }
          })
          .catch((error) => {
            console.error(error);
            this.setState({ loading: false })
          });
      });
  };

  addAttendees(attendees) {
    Object.keys(attendees).forEach((key) => 
      attendees[key].uid = key
    );
    this.setState((state) => {
      return {
        attendees: state.attendees
          .concat(Object.values(attendees))
          .sort((lhs, rhs) => {
            if (lhs.lastName === rhs.lastName) {
              return lhs.firstName > rhs.firstName
            }
            return lhs.lastName > rhs.lastName
          })
      }
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }

  updateSearch(searchInput) {
    this.setState({ searchFilter: searchInput.toLowerCase() })
  }

  render() {
    return (<DashboardContainer>
        <NewUsersMessage />
        <DashboardInsideContainer className="mt-5">
          <h1 className="text-xxxl text-weight-600 mb-4">Attendees</h1>
          <input type="text" placeholder="Search name" className="mb-5" onChange={event => this.updateSearch(event.target.value)} />
          <AttendeesGrid>
            {this.state.loading &&
              [1, 2, 3, 4, 5, 6].map((index) => (
                <UserLoadingPreview key={index} />
              ))
            }

            {!this.state.loading &&
              this.state.attendees.filter((attendee) => {
                return this.state.searchFilter === "" ||
                  `${attendee.firstName} ${attendee.lastName}`.toLowerCase().includes(this.state.searchFilter)
              }).slice(0, this.state.displayCount).map((value, index) => (
                <UserPreview key={value.uid} user={value} index={index} />
              ))
            }
          </AttendeesGrid>
        </DashboardInsideContainer>
      </DashboardContainer>
    );
  }
}

export default AttendeesScreen;
