import styled from "styled-components";
import ProfileSocialLinks, { ProfileSocialLink } from "components/ProfileSocialLinks";
import { useHistory } from 'react-router-dom';

const PreviewContainer = styled.div`
  box-shadow: 0px 4px 17px rgba(103, 94, 94, 0.08);
  cursor: pointer;
  color: #2c2929;
  transition: box-shadow 0.8s ease;

  border-radius:10px;

  :hover {
    color: #2c2929;
    box-shadow: 0px 10px 20px rgba(103, 94, 94, 0.2);
  }
`;

const HeaderContainer = styled.div`
  padding: 10px 10px 10px 20px;
  font-weight:500;
  font-size:20px;
  display: flex;
  flex-direction: column;

  border-top-left-radius:10px;
  border-top-right-radius:10px;
`;

const BodyContainer = styled.div`
  padding: 10px 20px;
  min-height: 130px;

  img.profile-pic {
    height:71px;
    width:71px;
    border-radius:35.5px;
    border-width:1.5px;
    border-style:solid;
  }

  .sidebar {
    display:table-cell;
    width:73px;
  }
`;

const EventName = styled.div`
  width: 70%;
`;

const eventHeadingColors = [
  "#2BE3CD",
  "#FFEA2E",
  "#36B6FF",
  "#FF4768",
  "#FF8C38",
];

export const UserPreview = ({ user, index, userId }) => {
  const socialKeys = Object.keys(user.socials);

  const history = useHistory();
  const navigateToDetailScreen = () => {
    const id = (user.uid ? user.uid : user.id) ? (user.uid ? user.uid : user.id) : userId
    history.push('/attendees/' + id);
  };

  return (
    <PreviewContainer onClick={() => navigateToDetailScreen()}>
      <HeaderContainer style={{ background: eventHeadingColors[index % 5] }}>
        <EventName className="text-xxl text-weight-600">{user.firstName} {user.lastName}</EventName>
      </HeaderContainer>
      <BodyContainer>
        <div className="row">
          <div className="col">
            <div className="mb-2 text-cobble text-weight-500">{[user.role, user.organisation].filter((value) => value !== "").join(", ")}</div>
            { socialKeys.length < 3 &&
              socialKeys.map((key) => {
                return <ProfileSocialLink key={key} type={key} inline={false} social={user.socials[key]} />
              })
            }
            { socialKeys.length >= 3 &&
              <ProfileSocialLinks socials={user.socials} />
            }
          </div>
          { user.thumbnailURL &&
          <div className="sidebar">
            <img aria-hidden 
              loading="lazy"
              alt={"Profile Picture for" + user.firstName + ' ' + user.lastName } 
              className="profile-pic" src={user.thumbnailURL} 
              style={{borderColor: eventHeadingColors[index % 5]}} />
          </div>
          }
        </div>
      </BodyContainer>
    </PreviewContainer>
  );
};

const LoadingContainer = styled.div`
  animation: blinker 1.5s linear infinite;
  border-radius: 6px;

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;

const LoadingContainerHeader = styled.div`
  height: 160px;
  background-color: #e8e8e8;
`;

const LoadingContainerBody = styled.div`
  height: 135px;
  background-color: #f9f9f9;
`;

export const UserLoadingPreview = () => (
  <LoadingContainer>
    <LoadingContainerHeader />
    <LoadingContainerBody />
  </LoadingContainer>
);
