import { useState, useEffect } from "react";
import firebase from "firebase/app";
import DashboardContainer, {
  DashboardInsideContainer,
} from "components/DashboardContainer";
import NewUsersMessage from "components/NewUsersMessage";
import styled from "styled-components";
import { EventLoadingPreview, EventPreview } from "components/EventPreview";
import { useContext } from "react";
import { AccountContext } from "Account/store";
import { Redirect } from "react-router-dom";
import { getNestedData } from "components/helpers";

const EventsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 40px;
  width: 70%;
  max-width: 700px;
  padding-bottom: 50px;

  @media only screen and (max-width: 1270px) {
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  @media only screen and (min-width: 1600px) {
    width: 80%;
    max-width: 1200px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const DashboardScreen = () => {
  const [state] = useContext(AccountContext);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (state.user) {
      setLoading(true);

      const dbRef = firebase.database().ref(`userEvents/${state.user.uid}`);

      getNestedData(dbRef, "events").then((value) => {
        const events = value;
        if (events) {
          const sortedEvents = events.sort(function (a, b) {
            return new Date(a.start_date) - new Date(b.start_date);
          });

          setEvents(sortedEvents);
        }
        setLoading(false);
      });
    }
  }, [state.user]);

  // If there's no user, redirect to public list of events
  if (!state.user) {
    return <Redirect to="/events" />;
  }

  return (
    <DashboardContainer>
      <NewUsersMessage />
      <DashboardInsideContainer className="mt-5">
        <h1 className="text-xxxl text-weight-600 mb-4">Your events</h1>
        <EventsGrid>
          {loading &&
            [1, 2, 3, 4, 5, 6].map((index) => (
              <EventLoadingPreview key={index} />
            ))}
          {!loading &&
            events.map((event, index) => (
              <EventPreview
                slug={event.id}
                event={event}
                index={index}
                key={index}
              />
            ))}
        </EventsGrid>
        {events.length === 0 && (
          <>
            <div className="mb-4">
              You haven’t RSVP’d on any of our events yet.
            </div>
            <a href="/events" className="text-weight-600 text-lg">
              Browse and RSVP
            </a>
          </>
        )}
      </DashboardInsideContainer>
    </DashboardContainer>
  );
};

export default DashboardScreen;
