import React, { useState, useEffect } from 'react'
import DashboardContainer, {
    DashboardInsideContainer,
  } from "components/DashboardContainer";
import styled from "styled-components";
import { EventLoadingPreview } from "components/EventPreview";
import VolunteerPreview from "components/VolunteerPreview"
import firebase from "firebase/app"

const VolunteersGrid = styled.div`
  display: grid;
  grid-templates-row: 1fr;
  grid-row-gap: 30px;
  margin-bottom: 100px;
`
const VolunteerTagline = styled.p`
    color: #677E9A;
    max-width: 779px;
    font-weight: 500;
    font-size: calc(12px + 0.6vw);
    margin-top: calc(12px + 0.6vw);
`

const headingColors = [
    "#2BE3CD",
    "#FFEA2E",
    "#36B6FF",
    "#FF4768",
    "#FF8C38",
];

const Volunteers = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})

    const fetchImage = (uid, callback) => {
        firebase
            .database()
            .ref('users/' + uid)
            .get()
            .then((snapshot) => {
                if (snapshot.exists()) {
                    callback(snapshot.val())
                }
            })
            .catch((error) => console.error(error))
    }

    useEffect(() => {
        fetch("https://api.airtable.com/v0/appZGpRTkEij893tS/Volunteers?maxRecords=100&view=Grid+view", {
            "headers": {
                "authorization": "Bearer keyNfd02JL9fMEUlx",
                "x-airtable-application-id": "appZGpRTkEij893tS",
                "content-type": "application/json",
                "x-airtable-user-agent": "Airtable.js/undefined"
            },
            "method": "GET",
            "mode": "cors"
        })
            .then(res => res.json())
            .then(json => {
                setData(json.records.reduce((accumulator, volunteer) => {
                    const fields = volunteer['fields']

                    if (Object.keys(fields).indexOf('Team') >= 0) {
                        const userID = fields['user_id']

                        fields['Team'].forEach((team) => {
                            if (Object.keys(accumulator).indexOf(team) < 0) {
                                accumulator[team] = []
                            }
                            accumulator[team].push({
                                id: userID,
                                name: fields['Name'],
                                role: fields['role']
                            })
                        })

                        
                        if (userID) {
                            fetchImage(userID, (profile) => {
                                fields['Team'].forEach((team) => {
                                    setData((volunteers) => {
                                        const teamVolunteers = volunteers[team]
                                        const index = teamVolunteers.findIndex((volunteer) => volunteer.id === userID)
                                        teamVolunteers[index].imageURL = profile.thumbnailURL ?? profile.imageURL
                                        teamVolunteers[index].socials = profile.socials
                                        return {
                                            ...volunteers,
                                            [team]: teamVolunteers
                                        }          
                                    })
                                })
                            })
                        }
                    }

                    return accumulator
                }, {}))
                setLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setLoading(false)
            })
    }, [])

    return (
        <DashboardContainer>
            <DashboardInsideContainer className="mt-5">
                <h1 className="text-xxxl text-weight-600 mb-0">Volunteers</h1>
                <VolunteerTagline>
                    We would like to thank everyone who shared their skills
                    and poured their efforts to making this event happen.
                </VolunteerTagline>
                <VolunteersGrid>
                    {loading && (
                        [1, 2, 3, 4, 5, 6].map((index) => (
                            <EventLoadingPreview key={index} />
                        ))
                    )}
                    {!loading && Object.keys(data).map((team, index) => (
                            <VolunteerPreview 
                                key={index}
                                backgroundColor={headingColors[index % headingColors.length]} 
                                team={team} 
                                volunteers={data[team]}></VolunteerPreview>
                        ))
                    }
                </VolunteersGrid>
            </DashboardInsideContainer>
        </DashboardContainer>
    )
}

export default Volunteers