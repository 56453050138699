// import { useContext } from "react";
// import { AccountContext } from "Account/store";
import styled from "styled-components";
// import ProfileImageContainer from "components/ProfileImageContainer";
import dayjs from "dayjs";
import globalConstants from "globalConstants";
import AttendeesIcon from "components/icons/AttendeesIcon";
import VideoIcon from "./icons/VideoIcon";

const PreviewContainer = styled.a`
  box-shadow: 0px 4px 17px rgba(103, 94, 94, 0.08);
  cursor: pointer;
  color: #2c2929;
  transition: box-shadow 0.8s ease;

  :hover {
    color: #2c2929;
    box-shadow: 0px 10px 20px rgba(103, 94, 94, 0.2);
  }
`;

const HeaderContainer = styled.div`
  padding: 10px 10px 20px 20px;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const BodyContainer = styled.div`
  padding: 10px 20px;
  min-height: 70px;
`;

const EventDate = styled.div`
  background-color: white;
  min-width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  align-self: flex-end;
  text-align: center;
  margin-bottom: 16px;
`;

const EventVideo = styled.div`
  position: absolute;
  top: 15px;
  left: 20px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  svg {
    margin-right: 8px;
  }
`;

const EventName = styled.div`
  width: 70%;
`;

const eventHeadingColors = [
  "#2BE3CD",
  "#FFEA2E",
  "#36B6FF",
  "#FF4768",
  "#FF8C38",
];

export const EventPreview = ({ slug, event, index }) => {
  // const [state] = useContext(AccountContext);

  const startDate = dayjs(
    event.start_date,
    globalConstants.dateFormats.firebase
  );
  const endDate = dayjs(event.end_date, globalConstants.dateFormats.firebase);
  const isSameDay = dayjs(event.start_date).isSame(event.end_date, "day");
  const isEventDone = dayjs(event.end_date).isBefore(dayjs());
  const offset = dayjs().format("Z");

  const eventDate = () => {
    if (isSameDay) {
      return startDate.format(globalConstants.dateFormats.monthWithDay);
    } else {
      return `${startDate.format(
        globalConstants.dateFormats.monthWithDay
      )}-${endDate.format(globalConstants.dateFormats.day)}`;
    }
  };

  const eventTime = () => {
    if (isSameDay) {
      return `${startDate.format(
        globalConstants.dateFormats.time
      )} - ${endDate.format(globalConstants.dateFormats.time)}`;
    } else {
      return `${startDate.format(
        globalConstants.dateFormats.monthWithDayAndTime
      )} - ${endDate.format(globalConstants.dateFormats.monthWithDayAndTime)}`;
    }
  };

  return (
    <PreviewContainer href={`/events/${slug}`}>
      <HeaderContainer style={{ background: eventHeadingColors[index % 5] }}>
        <EventDate className="text-lg text-weight-600">{eventDate()}</EventDate>
        {event.videoUrl && (
          <EventVideo>
            <VideoIcon />
            Video available!
          </EventVideo>
        )}
        <EventName className="text-xxl text-weight-600">{event.name}</EventName>
      </HeaderContainer>
      <BodyContainer>
        <div className="text-sm text-weight-500">{event.subtitle}</div>
        <div className="text-sm text-weight-600">
          {eventTime()} (GMT{offset})
        </div>
        {event.attendeesCount > 0 && (
          <div className="d-flex mt-2 mb-1">
            <AttendeesIcon />
            <div className="text-sm text-weight-500 ml-2 text-cobble">
              {event.attendeesCount} people{" "}
              {isEventDone ? "attended" : "attending"}
            </div>
          </div>
        )}
      </BodyContainer>
    </PreviewContainer>
  );
};

const LoadingContainer = styled.div`
  animation: blinker 1.5s linear infinite;
  border-radius: 6px;

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;

const LoadingContainerHeader = styled.div`
  height: 160px;
  background-color: #e8e8e8;
`;

const LoadingContainerBody = styled.div`
  height: 135px;
  background-color: #f9f9f9;
`;

export const EventLoadingPreview = () => (
  <LoadingContainer>
    <LoadingContainerHeader />
    <LoadingContainerBody />
  </LoadingContainer>
);
