import { useState, useEffect, useContext } from "react";
import firebase from "firebase/app";
import { AccountContext } from "Account/store";
import styled from "styled-components";
import dayjs from "dayjs";
import ProfileImageContainer from "components/ProfileImageContainer";
import { useParams } from "react-router-dom";
import DashboardContainer, {
  DashboardInsideContainer,
} from "components/DashboardContainer";
import DashboardLoading from "components/DashboardLoading";
import MentoringLabsDialog from "components/MentoringLabsDialog";
import WorldIcon from "components/icons/WorldIcon";
import FileIcon from "components/icons/FileIcon";

const MentoringLabHeader = styled.div`
  box-shadow: 0px 5px 5px rgba(103, 94, 94, 0.08);
  display: block;
  background-color: white;
  padding-top: 40px;
  z-index: 1;

  @media only screen and (min-width: 700px) {
    position: sticky;
    top: 0px;
  }
`;

const MentoringLabDate = styled.div`
  background: #2be3cd;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
  max-width: 100px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

const MentoringLabScreen = () => {
  let { id } = useParams();
  const [state] = useContext(AccountContext);

  const [loading, setLoading] = useState(false);
  const [mentoringLab, setMentoringLab] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    getMentoringLab();
    // eslint-disable-next-line
  }, [id]);

  const getMentoringLab = () => {
    setLoading(true);

    firebase
      .database()
      .ref("mentoringLabs/" + id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          setMentoringLab(snapshot.val());
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  if (loading || !mentoringLab) {
    return (
      <DashboardContainer>
        <DashboardLoading />
      </DashboardContainer>
    );
  } else {
    const mentor = mentoringLab?.mentor;
    const isMentoringLabDone = dayjs(mentoringLab.end_date).isBefore(dayjs());

    return (
      <DashboardContainer>
        <MentoringLabHeader>
          <DashboardInsideContainer>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center pb-4">
              <div className="d-flex flex-column flex-md-row">
                <MentoringLabDate>{mentoringLab.dates}</MentoringLabDate>
                <div className="ml-0 my-3 my-md-0 mx-md-3">
                  <div className="text-sm text-cobble">Mentoring Lab</div>
                  <div className="text-xxl text-weight-600">
                    {mentoringLab.name}
                  </div>
                  <div className="text-sm text-weight-600">
                    {mentor.firstName} {mentor.lastName}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column text-center">
                {!isMentoringLabDone && (
                  <>
                    {!state.user && (
                      <a
                        className="btn btn-primary px-5 py-2 text-weight-500"
                        href="/join"
                      >
                        Book a slot
                      </a>
                    )}
                    {state.user && (
                      <button
                        className="btn btn-primary px-5 py-2 text-weight-500"
                        onClick={() => setIsDialogOpen(true)}
                      >
                        Book a slot
                      </button>
                    )}
                  </>
                )}
                {isMentoringLabDone && (
                  <button
                    className="btn btn-primary px-5 py-2 text-weight-500"
                    disabled
                  >
                    Mentoring ended
                  </button>
                )}
              </div>
            </div>
          </DashboardInsideContainer>
        </MentoringLabHeader>
        <DashboardInsideContainer className="my-5">
          <div className="d-flex flex-column flex-md-row align-items-center">
            <ProfileImageContainer
              width="150px"
              height="150px"
              src={mentor.imageUrl}
              loading="lazy"
            />
            <div className="ml-md-4">
              <div className="text-xxxl text-weight-600">
                {mentor.firstName} {mentor.lastName}
              </div>
              <div className="text-xl text-weight-500">{mentor.profession}</div>
              {mentor.company && (
                <div className="text-cobble text-lg text-weight-500">
                  {mentor.company}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex flex-column my-5">
            <div className="row align-items-center no-gutters">
              <div className="col-12 col-sm-auto">
                <FileIcon />
              </div>
              <div className="col ml-sm-3">
                <div className="text-xl text-uppercase text-weight-600 text-purple">
                  Categories
                </div>
                <div className="text-lg text-weight-500">
                  {mentoringLab.categories}
                </div>
              </div>
            </div>
            <div className="row align-items-center mt-4 no-gutters">
              <div className="col-12 col-sm-auto">
                <WorldIcon />
              </div>
              <div className="col ml-sm-3">
                <div className="text-xl text-uppercase text-weight-600 text-purple">
                  Languages
                </div>
                <div className="text-lg text-weight-500">
                  {mentoringLab.language}
                </div>
              </div>
            </div>
          </div>
          {mentor.description && (
            <>
              <hr />
              <div className="my-5">
                <div className="text-xl mt-4 text-uppercase text-weight-600 text-cobble">
                  About
                </div>
                <div className="text-lg text-weight-500">
                  {mentor.description}
                </div>
              </div>
            </>
          )}
        </DashboardInsideContainer>
        <MentoringLabsDialog
          isOpen={isDialogOpen}
          mentorLink={mentoringLab.calendlyLink}
          onRequestClose={() => setIsDialogOpen(false)}
        />
      </DashboardContainer>
    );
  }
};

export default MentoringLabScreen;
