import { useState, useEffect, useContext } from "react";
import firebase from "firebase/app";
import axios from "axios";

import { AccountContext } from "Account/store";
import styled from "styled-components";
import classNames from "classnames";
import dayjs from "dayjs";
import globalConstants from "globalConstants";
import { useParams } from "react-router-dom";
import DashboardContainer, {
  DashboardInsideContainer,
} from "components/DashboardContainer";
import ProfileImageContainer from "components/ProfileImageContainer";
import DashboardLoading from "components/DashboardLoading";
import ProfileSocialLinks from "components/ProfileSocialLinks";
import { getNestedData } from "components/helpers";
import {
  UserPreview,
  UserLoadingPreview,
} from "components/UserView/UserPreview";
import AttendeesIcon from "components/icons/AttendeesIcon";
import AddToCalendarButton from "components/AddToCalendarButton";

const EventHeader = styled.div`
  box-shadow: 0px 5px 5px rgba(103, 94, 94, 0.08);
  display: block;
  background-color: white;
  padding-top: 40px;
  z-index: 1;

  @media only screen and (min-width: 700px) {
    position: sticky;
    top: 0px;
  }
`;

const EventDate = styled.div`
  background: #2be3cd;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
  max-width: 80px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

const EventMainContainer = styled(DashboardInsideContainer)`
  margin-top: 50px;
`;

const AttendeesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 40px;
  width: 80%;

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
`;

const IFrameContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const EventScreen = () => {
  let { id } = useParams();
  const [state] = useContext(AccountContext);

  const [tab, setTab] = useState("details");
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState({});
  const [attendees, setAttendees] = useState([]);
  const [attendeesLoading, setAttendeesLoading] = useState(false);
  const [isUserGoing, setIsUserGoing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [code, setCode] = useState("");
  const [gotFreeSpace, setGotFreeSpace] = useState(false);
  const [gotError, setGotError] = useState(false);
  const [password, setPassword] = useState("");

  const mainButtonClass = "btn btn-primary px-5 py-2 text-weight-500";
  const dangerButtonClass = "btn btn-danger px-5 py-2 text-weight-500";

  const startDate = dayjs(
    event.start_date,
    globalConstants.dateFormats.firebase
  );
  const endDate = dayjs(event.end_date, globalConstants.dateFormats.firebase);
  const isSameDay = dayjs(event.start_date).isSame(event.end_date, "day");
  const isEventDone = dayjs(event.end_date).isBefore(dayjs());
  const offset = dayjs().format("Z");
  const eventDate = () => {
    if (isSameDay) {
      return startDate.format(globalConstants.dateFormats.monthWithDay);
    } else {
      return `${startDate.format(
        globalConstants.dateFormats.monthWithDay
      )}-${endDate.format(globalConstants.dateFormats.day)}`;
    }
  };

  const eventTime = () => {
    if (isSameDay) {
      return `${startDate.format(
        globalConstants.dateFormats.time
      )} - ${endDate.format(globalConstants.dateFormats.time)}`;
    } else {
      return `${startDate.format(
        globalConstants.dateFormats.monthWithDayAndTime
      )} - ${endDate.format(globalConstants.dateFormats.monthWithDayAndTime)}`;
    }
  };

  const requestSpace = () => {
    let url = process.env.functionurl;
    setGotError(false);

    axios
      .get(url, {
        params: {
          directory: `events/mentorship-labs/spaces`, // ${event.id}
          email: state.profile.emailAddress,
          name: state.profile.firstName + " " + state.profile.lastName,
          role: state.profile.role,
          affiliation: state.profile.organisation,
          eventName: event.name,
        },
      })
      .then(
        (response) => {
          var code = response.data.code;
          if (code.includes("FREE-")) {
            var password = response.data.password;
            setPassword(password);

            code = code.replace("FREE-", "");
            setGotFreeSpace(true);
          }
          code = code.replace("\\", "/");
          code = "https://gather.town/app/" + code;
          setCode(code);

          setGotError(false);
          setShowModal(true);
        },
        (error) => {
          setCode(error.response.data);
          setGotError(true);
          setShowModal(true);
        }
      );
  };

  const changeAttendingStatus = (isAttending) => {
    const userId = state.user.uid;
    const eventId = event.id;

    const attendeesCount = isAttending
      ? (event.attendeesCount || 0) + 1
      : (event.attendeesCount || 0) > 0
      ? event.attendeesCount - 1
      : 0;

    const updates = {
      [`userEvents/${userId}/${eventId}`]: isAttending ? true : null,
      [`eventAttendees/${eventId}/${userId}`]: isAttending ? true : null,
      [`events/${eventId}/attendeesCount`]: attendeesCount,
    };

    firebase
      .database()
      .ref()
      .update(updates)
      .then(() => {
        getEvent();
        getAttendees();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const reserve = () => {
    changeAttendingStatus(true);
  };

  const remove = () => {
    changeAttendingStatus(false);
  };

  const goToEvent = () => {
    requestSpace();
  };

  const openEvent = () => {
    setShowModal(false);
    window.open(code, "_blank");
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const goToTemporaryLink = () => {
    const userId = state.user.uid;
    const eventId = event.id;
    const updates = {
      [`eventGuests/${eventId}/${userId}`]: true,
    };

    firebase
      .database()
      .ref()
      .update(updates)
      .then(() => {
        window.open(event.temporaryLink, "_blank");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getEvent();
    // eslint-disable-next-line
  }, [id]);

  const getAttendees = () => {
    setAttendeesLoading(true);
    const dbRef = firebase.database().ref(`eventAttendees/${event.id}`);
    getNestedData(dbRef, "users").then((value) => {
      if (value) {
        setAttendees(value.filter((n) => n));
      }
      setAttendeesLoading(false);
    });
  };

  const getEvent = () => {
    setLoading(true);

    firebase
      .database()
      .ref("events/" + id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          setEvent({ ...snapshot.val(), id: snapshot.key });
          if (state.user) {
            firebase
              .database()
              .ref(`userEvents/${state.user.uid}/${snapshot.key}`)
              .once("value")
              .then((snapshot) => {
                if (snapshot.exists() && snapshot.val()) {
                  setIsUserGoing(true);
                } else {
                  setIsUserGoing(false);
                }
              });
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const getRsvpButton = () => {
    if (event.externalLink) {
      return (
        <a
          href={event.externalLink}
          className={mainButtonClass}
          target="_blank"
          rel="noopener noreferrer"
        >
          Go to event
        </a>
      );
    }

    if (isEventDone) {
      return (
        <button className={mainButtonClass} disabled>
          Event ended
        </button>
      );
    } else if (isUserGoing) {
      return (
        <button className={dangerButtonClass} onClick={remove}>
          Remove RSVP
        </button>
      );
    } else {
      return (
        <button className={mainButtonClass} onClick={reserve}>
          RSVP to event
        </button>
      );
    }
  };

  const getGoToEventButton = () => {
    if (event.externalLink) {
      return null;
    }

    if (!isEventDone && event.isOpen && isUserGoing) {
      if (event.temporaryLink) {
        return (
          <button
            className={classNames(mainButtonClass, "mt-2")}
            onClick={goToTemporaryLink}
          >
            Go to event
          </button>
        );
      } else {
        return (
          <button
            className={classNames(mainButtonClass, "mt-2")}
            onClick={goToEvent}
          >
            Go to event
          </button>
        );
      }
    }

    // As a catch all, this secondary button won't return anything if it doesn't satisfy the above conditions
    return null;
  };

  if (loading) {
    return (
      <DashboardContainer>
        <DashboardLoading />
      </DashboardContainer>
    );
  }

  return (
    <DashboardContainer>
      <EventHeader>
        <div className={showModal ? "modal d-block" : "modal d-none"}>
          {gotError
            ? [
                <div
                  className={
                    showModal
                      ? "modal-container d-flex"
                      : "modal-container d-none"
                  }
                >
                  <div>
                    <h2 className="text-center">{code}</h2>
                    <h1 className="modal-container-h1 text-center">❌</h1>
                    <h4 className="text-center modal-subtitle">
                      If this issue persists, please contact a member of the
                      WWDC Community team.
                    </h4>
                  </div>
                  <button
                    onClick={closeModal}
                    className="btn btn-primary px-5 py-2 text-weight-500 modal-button"
                  >
                    Close
                  </button>
                </div>,
              ]
            : [
                <div
                  className={
                    showModal
                      ? "modal-container d-flex"
                      : "modal-container d-none"
                  }
                >
                  {gotFreeSpace ? (
                    <div>
                      <h2 className="text-center">
                        We Found A Free Space For You!
                      </h2>
                      <h4 className="text-center modal-passsword">
                        Space Password: {password}
                      </h4>
                      <h1 className="modal-container-h1 text-center">🥳</h1>
                      <h4 className="text-center modal-subtitle">
                        All of our main spaces are currently filled, but we did
                        find you an open free space. A moderator will find you a
                        spot in the main event soon after you join this room.
                      </h4>
                    </div>
                  ) : (
                    <div>
                      <h2 className="text-center">We Found A Space For You!</h2>
                      <h1 className="modal-container-h1 text-center">🥳</h1>
                    </div>
                  )}
                  <button
                    onClick={openEvent}
                    className="btn btn-primary px-5 py-2 text-weight-500 modal-button"
                  >
                    Go To Space!
                  </button>
                </div>,
              ]}
        </div>

        <DashboardInsideContainer>
          <div className="d-flex flex-column flex-md-row justify-content-between  align-items-md-center pb-3">
            <div className="d-flex flex-column flex-md-row">
              <EventDate>{eventDate()}</EventDate>
              <div className="ml-0 my-3 my-md-0 ml-md-3">
                <div className="text-sm text-cobble">{event.subtitle}</div>
                <div className="text-xxxl text-weight-600">{event.name}</div>
                <div className="text-sm text-weight-600">
                  {eventTime()} (GMT{offset})
                </div>
              </div>
            </div>
            <div className="d-flex flex-column text-center">
              {!state.user && (
                <a className={mainButtonClass} href="/join">
                  Go to event
                </a>
              )}
              {state.user && getRsvpButton()}
              {state.user && getGoToEventButton()}
              {!event.isOpen && !isEventDone && isUserGoing && !event.noRsvp && (
                <div className="mt-3">
                  <AddToCalendarButton
                    event={event}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
              )}
            </div>
          </div>
          <hr />
          <div className="d-flex flex-column flex-md-row justify-content-start justify-content-between align-items-center pb-3">
            <div className="d-flex mb-3 mb-md-0">
              <div
                className={classNames("px-4 py-2 pointer", {
                  "text-weight-600 text-blue-underline": tab === "details",
                })}
                onClick={() => setTab("details")}
              >
                Details
              </div>
              {event.attendeesCount > 0 && (
                <div
                  className={classNames("px-4 py-2 pointer", {
                    "text-weight-600 text-blue-underline": tab === "attendees",
                  })}
                  onClick={() => {
                    getAttendees();
                    setTab("attendees");
                  }}
                >
                  Attendees
                </div>
              )}

              {event.videoUrl && (
                <div
                  className={classNames("px-4 py-2 pointer", {
                    "text-weight-600 text-blue-underline": tab === "video",
                  })}
                  onClick={() => setTab("video")}
                >
                  Video
                </div>
              )}
            </div>
            {event.attendeesCount > 0 && (
              <div className="d-flex">
                <AttendeesIcon />
                <div className="text-weight-500 text-cobble ml-2">
                  {event.attendeesCount} people{" "}
                  {isEventDone ? "attended" : "attending"}
                </div>
              </div>
            )}
          </div>
        </DashboardInsideContainer>
      </EventHeader>
      {tab === "details" && (
        <EventMainContainer>
          <div className="mb-5">
            <div className="text-xl text-weight-600 mb-2">About the Event</div>
            <p className="text-weight-500">{event.description}</p>
          </div>

          {event.prizes && (
            <div className="mb-5">
              <div className="text-xl text-weight-600">Prizes</div>
              {event.prizes.map((prize) => (
                <div className="mt-4">
                  <div className="text-cobble text-uppercase text-weight-500">{prize.name}</div>
                  <div className="text-lg text-weight-500">{prize.description}</div>
                </div>
              ))}
            </div>
          )}

          {event.speakers && (
            <div className="mb-5">
              <div className="text-xl text-weight-600">Speakers</div>
              {event.speakers.map((speaker) => (
                <div
                  className="d-flex flex-column flex-md-row mt-4 align-items-md-center"
                  key={speaker.firstName}
                >
                  <ProfileImageContainer
                    width="80px"
                    height="80px"
                    src={speaker.imageUrl}
                    alt={`Speaker ${speaker.firstName}`}
                    loading="lazy"
                  />
                  <div className="ml-0 ml-md-4 mt-3 mt-md-0">
                    <div className="d-flex text-xl text-weight-500 mb-2">
                      <div className="mr-2">
                        {speaker.firstName} {speaker.lastName}
                      </div>
                    </div>
                    <div>{speaker.description}</div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {event.commentators && (
            <div className="mb-5">
              <div className="text-xl text-weight-600">Commentators</div>
              {event.commentators.map((commentator) => (
                <div
                  className="d-flex flex-column flex-md-row mt-4 align-items-md-center"
                  key={commentator.firstName}
                >
                  <ProfileImageContainer
                    width="80px"
                    height="80px"
                    src={commentator.imageUrl}
                    alt={`Commentator ${commentator.firstName}`}
                    loading="lazy"
                  />
                  <div className="ml-0 ml-md-4 mt-3 mt-md-0">
                    <div className="d-flex text-xl text-weight-500 mb-2">
                      <div className="mr-2">
                        {commentator.firstName} {commentator.lastName}
                      </div>
                    </div>
                    <div>{commentator.description}</div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {event.host && (
            <div className="mb-5">
              <div className="text-xl text-weight-600">Host</div>
              <div className="d-flex flex-column flex-md-row mt-4 align-items-md-center">
                <ProfileImageContainer
                  width="80px"
                  height="80px"
                  src={event.host.imageUrl}
                  alt={`Host ${event.host.firstName}`}
                  loading="lazy"
                />
                <div className="ml-0 ml-md-4 mt-3 mt-md-0">
                  <div className="d-flex text-xl text-weight-500 mb-2">
                    <div className="mr-2">
                      {event.host.firstName} {event.host.lastName}
                    </div>
                  </div>
                  <div>{event.host.description}</div>
                </div>
              </div>
            </div>
          )}

          {event.organizer && (
            <div className="mb-5">
              <div className="text-xl text-weight-600">Organizer</div>
              <div className="d-flex flex-column flex-md-row mt-4 align-items-md-center">
                <ProfileImageContainer
                  width="80px"
                  height="80px"
                  src={event.organizer.imageUrl}
                  alt={`Organizer ${event.organizer.name}`}
                  loading="lazy"
                />

                <div className="ml-0 ml-md-4 mt-3 mt-md-0">
                  <div className="d-flex text-xl text-weight-500 mb-2">
                    <div className="mr-2">{event.organizer.name}</div>
                    <ProfileSocialLinks profile={event.organizer} />
                  </div>
                  <div>{event.organizer.description}</div>
                </div>
              </div>
            </div>
          )}

          {event.partner && (
            <div className="mb-5">
              <div className="text-xl text-weight-600">Partner</div>
              <div className="d-flex flex-column flex-md-row mt-4 align-items-md-center">
                <ProfileImageContainer
                  width="80px"
                  height="80px"
                  src={event.partner.imageUrl}
                  alt={`Partner ${event.partner.name}`}
                  loading="lazy"
                />

                <div className="ml-0 ml-md-4 mt-3 mt-md-0">
                  <div className="d-flex text-xl text-weight-500 mb-2">
                    <div className="mr-2">{event.partner.name}</div>
                    <ProfileSocialLinks profile={event.partner} />
                  </div>
                  <div>{event.partner.description}</div>
                </div>
              </div>
            </div>
          )}

          {event.team && (
            <div className="mb-5">
              <div className="text-xl text-weight-600">Team</div>
              {event.team.map((teamMember) => (
                <div
                  className="d-flex flex-column flex-md-row mt-4 align-items-md-center"
                  key={teamMember.firstName}
                >
                  <ProfileImageContainer
                    width="80px"
                    height="80px"
                    src={teamMember.imageUrl}
                    alt={`Team Member ${teamMember.firstName}`}
                    loading="lazy"
                  />
                  <div className="d-flex flex-column ml-0 ml-md-4 mt-3 mt-md-0">
                    <div className="text-xl text-weight-500">
                      {teamMember.firstName} {teamMember.lastName}
                    </div>
                    <div>{teamMember.description}</div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {event.volunteers && (
            <div className="mb-5">
              <div className="text-xl text-weight-600">Volunteers</div>
              {event.volunteers.map((volunteer) => (
                <div
                  className="d-flex flex-column flex-md-row mt-4 align-items-md-center"
                  key={volunteer.firstName}
                >
                  <ProfileImageContainer
                    width="80px"
                    height="80px"
                    src={volunteer.imageUrl}
                    alt={`Volunteer ${volunteer.firstName}`}
                    loading="lazy"
                  />
                  <div className="d-flex flex-column ml-0 ml-md-4 mt-3 mt-md-0">
                    <div className="text-xl text-weight-500">
                      {volunteer.firstName} {volunteer.lastName}
                    </div>
                    <div>{volunteer.description}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </EventMainContainer>
      )}
      {tab === "attendees" && (
        <EventMainContainer>
          <AttendeesGrid>
            {attendeesLoading &&
              [1, 2, 3, 4, 5, 6].map((index) => (
                <UserLoadingPreview key={index} />
              ))}

            {/* The conditional statements should be done in firebase / server side but in the interest of time this is the alternative */}
            {!attendeesLoading &&
              Object.keys(attendees).map((key, index) => {
                if (
                  state.user &&
                  ["attendees", "anyone"].includes(attendees[key].privacy)
                ) {
                  return (
                    <UserPreview
                      key={key}
                      user={attendees[key]}
                      index={index}
                    />
                  );
                } else if (!state.user && attendees[key].privacy === "anyone") {
                  return (
                    <UserPreview
                      key={key}
                      user={attendees[key]}
                      index={index}
                    />
                  );
                }

                return null;
              })}
          </AttendeesGrid>
        </EventMainContainer>
      )}
      {tab === "video" && (
        <EventMainContainer>
          <div className="text-weight-600 text-xxl mb-3">
            This event is done, but we've got it recorded!
          </div>
          {state.user && (
            <IFrameContainer>
              <iframe
                width="420"
                height="315"
                src={event.videoUrl}
                title="Event Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </IFrameContainer>
          )}
          {!state.user && (
            <div className="text-lg">
              Please{" "}
              <a className="text-pink" href="/join">
                Register
              </a>{" "}
              or{" "}
              <a className="text-pink" href="/login">
                Login
              </a>{" "}
              to view the video.
            </div>
          )}
        </EventMainContainer>
      )}
    </DashboardContainer>
  );
};

export default EventScreen;
