import styled from 'styled-components';

export const SplashScreenContainer = styled.div`
    .text-center {
        text-align:center;
    }

    footer {
        padding-top:20px;
        padding-bottom:20px;
        background: #1E1A47;
    }
    footer a {
        color:white;
    }
    .disclaimers {
        padding-top:18px;
        color: #9B98BF;
        font-size: 14px;
        font-weight:500;
        padding-bottom:50px;
    }
    .event img {
        margin-bottom:40px;
    }
    .event div:first-of-type {
        text-align:center;
    }
    .event h4 {
        font-size:24px;
        font-weight:600;
        margin-bottom:10px;
    }
    .event p {
        font-size:20px;
        color: #F2E3E3;
        margin-bottom:10px;
    }
    .event .date {
        font-size:18px;
        font-weight:500;
    }
    .event div:last-of-type {
        border-bottom: 1px solid #2A1D4A;
        margin-bottom:44px;
        padding-bottom:10px;
    }
    .community-heroes img {
        width: 100%;
    }

    @media only screen and (max-width: 600px) {
        footer .footer-links div {
            margin-top: 20px;
        }
        .community-heroes {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 30px;
            grid-row-gap: 30px;
            width: 85%;
            margin: 0 auto;
        }
        .cta-links {
            font-size: 18px;
            margin-top: 20px;
        }
    }

    @media only screen and (min-width: 600px) {
        footer .footer-links div:not(:last-child) {
            margin-right: 30px;
        }
        .community-heroes {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 60px;
            grid-row-gap: 30px;
            width: 70%;
            margin: 0 auto;
        }
    }
`;

export const SplashButton = styled.button`
    font-size:18px;
    padding: 10px 40px 10px 40px;
    max-width: 80%;
    width: 370px;

     
    @media only screen and (min-width: 600px) {
        font-size:24px;
    }
`;

export const SplashHeader = styled.div`
    background: url("/splashscreen.png") no-repeat center center;
    background-size:cover;
    font-size:24px;
    text-align:center;

    img {
        max-width: 100%;
    }

    span {
        font-size:20px;
        margin:18px;
    }

    p {
        width:100%;
        text-align:right;
    }
    p a {
        font-weight:700;
        color:white !important;
    }

    h2 {
        font-weight:600;
        font-size: 20px;
        margin-bottom:40px;
    }

    .get-a-ticket {
        font-size: 18px;
        line-height: 21px;
        display: block;
    }

    @media only screen and (max-width: 600px) {
        margin-bottom: 30px;
        .content {
            padding: 10px 20px 40px 20px;
    
            img {
                padding-top: 50px;
            }
        }
    }
    
    @media only screen and (min-width: 600px) {
        min-height:620px;
        padding: 40px;
        margin-bottom: 90px;

        .content {
            margin-top: 100px;
        }

        h2 {
            font-size: 36px;
        }

        .get-a-ticket {
            font-size: 24px;
            line-height: 28px;
        }
    }
`;

export const SplashHeading = styled.h3`
    font-size:24px;
    font-weight:600;
    display:inline-block;
    padding-bottom:16px;
    border-bottom: 1px solid #EB5454;
    margin:auto;
    margin-top:90px;
    margin-bottom:20px;
`;

export const LastYear = styled.a`
    color: #FFCD4B !important;
    font-weight: 600;
`;

export const Sponsors = styled.div`
    .row {
        padding-top:60px;
        text-align:center;
    }
    .row p {
        text-align:left;
    }
    .row img {
        width:50%;
    }
`;

export const SponsorHeading = styled.h4`
    text-align:center;
    color:#FFE897;
    margin-bottom:46px;
    font-weight:600;
    font-size:24px;
    text-transform:uppercase;
`;

export const SponsorLogo = styled.img`
    width:100%;
    margin-bottom:46px;
`;

export const SupportColumn = styled.div`
    padding-top:50px;
    text-align:center;

    img {
        font-size:100px;
        padding-bottom:35px;
    }
    h5 {
        font-size:24px;
    }
    button {
        font-size:20px;
    }
`;

export const CommunityHeroes = styled.div`
    padding-top:50px;
    text-align:center;
`;
export const Team = styled.div`
    padding-top:50px;
    text-align:center;

    button {
        background-color: Transparent;
        border: none; 
        cursor: pointer;
        color: #FFCD4B;

    }
`;
export const Attendees = styled.div`
    text-align:center;

    button {
        background-color: Transparent;
        border: none; 
        cursor: pointer;
        color: #FFCD4B;
    }
`;
export const EventVolunteers = styled.div`
    text-align:center;
    button {
        background-color: Transparent;
        border: none; 
        cursor: pointer;
        color: #FFCD4B;

    }
`;
